import api from 'shared/api';

export const changePassword = data => api.post('/api/v1/rest-auth/password/change/', data);

export const updateUserData = (id, data) => api.patch(`/api/v1/users/${id}/`, data);

export const disable2FA = userId => api.patch(`/api/v1/users/${userId}/`, { twofa_required: false });

export const initTwofaSetup = () => api.post('/api/v1/auth/twofa_setup_init/');

export const finishTwofaSetup = code => api.post('/api/v1/auth/twofa_setup_complete/', { code });
