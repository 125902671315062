import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid4 } from 'uuid';
import { get } from 'lodash';
import {
  sendErrorReport,
  getErrMsg,
} from 'shared/helpers';
import {
  ConfirmationPopup,
  ContentLoader,
  Notification,
} from 'shared/components';
import { fetchNewToken, getDeviceCertificate } from 'src/license/actions';

const HardwareKeyProvision = ({
  closeCb,
  confirmCb,
  licenseID, // can be null
  companyID,
}) => {
  const uuidID = useRef(uuid4());

  const [fetchingToken, setFetchingToken] = useState(true);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);

  const getNewToken = () => {
    fetchNewToken()
      .then((response) => {
        const newToken = get(response, 'data.token');
        setToken(newToken);
        setFetchingToken(false);
      })
      .catch((err) => {
        const errMsg = getErrMsg(err, 'message');
        sendErrorReport(err, 'Cannot fetch new token');
        Notification('error', __('Error occured'), errMsg);
        setFetchingToken(false);
      });
  };

  const checkForCertificate = () => {
    getDeviceCertificate(uuidID.current, companyID)
      .then((response) => {
        const certificate = get(response, 'data.results[0].certificate');
        if (certificate) {
          Notification('success', __('Hardware key provisioned successfully'));
          confirmCb();
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getNewToken();
  }, []);

  useEffect(() => {
    if (token && loading) {
      const interval = setInterval(() => {
        checkForCertificate();
      }, 5 * 1000);

      return () => clearInterval(interval);
    }
    return () => {};
  }, [token, loading]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const licenseIdParam = licenseID ? `&license_id=${licenseID}` : '';
    const url = `licensespring://hello?token=${token}&uuid=${uuidID.current}&company=${companyID}${licenseIdParam}`;

    window.open(url);
    setLoading(true);
  };

  if (fetchingToken || loading) {
    return (
      <ConfirmationPopup
        confirmCb={() => {}}
        closeCb={closeCb}
        disabled={fetchingToken || loading}
        title={__('Do you want to provision the hardware key certificate?')}
      >
        <div className="HardwareKeyProvision">
          <ContentLoader text={loading ? __('Provisioning hardware key certificate...') : ''} />
        </div>
      </ConfirmationPopup>
    );
  }

  return (
    <ConfirmationPopup
      confirmCb={handleSubmit}
      closeCb={closeCb}
      disabled={loading}
      title={__('Do you want to provision the hardware key certificate?')}
    >
      <div className="HardwareKeyProvision">
        <div className="HardwareKeyProvision-message">
          <p>{__('This will create a unique certificate and import it to the plugged in hardware key.')}</p>
          {/* <p>{__('Once saved, keep it safe and follow the instructions to successfully import it to the hardware key.')}</p> */}
          <p>{__('The provisioning process could last for a few minutes, depending on your network connection. Do not close this popup or leave this page before it is finished with provisioning.')}</p>
          <p><strong>{__('The same certificate cannot be generated again.')}</strong></p>
        </div>
      </div>
    </ConfirmationPopup>
  );
};

HardwareKeyProvision.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  confirmCb: PropTypes.func.isRequired,
  licenseID: PropTypes.number,
};

HardwareKeyProvision.defaultProps = {
  licenseID: null,
};

export default HardwareKeyProvision;
