import React, { useState } from 'react';
import { Prompt } from 'react-router-dom';
import { sendErrorReport } from 'shared/helpers';
import {
  Button,
  DescriptionCard,
  Label,
  Notification,
  Page,
  PasswordInput,
} from 'shared/components';
import {
  validateCurrentPassword,
  debouncedValidateCurrentPassword,
  validatePassword,
  debouncedValidatePassword,
  validatePasswordAgain,
  debouncedValidatePasswordAgain,
} from 'shared/validation';
import { changePassword } from 'src/user/actions';
import './styles.scss';

const ChangePasswordContainer = () => {
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [passwordAgainError, setPasswordAgainError] = useState('');

  const validateCurrentPass = async () => {
    let errors;
    try {
      errors = await validateCurrentPassword(currentPassword);
      setCurrentPasswordError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate current password change');
    }
    if (errors) {
      return false;
    }
    return true;
  };

  const validateNewPass = async () => {
    let errors;
    try {
      errors = await validatePassword(password);
      setPasswordError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new password change');
    }
    if (errors) {
      return false;
    }
    return true;
  };

  const validateAgainPass = async () => {
    let errors;
    try {
      errors = await validatePasswordAgain(password, passwordAgain);
      setPasswordAgainError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate again password change');
    }
    if (errors) {
      return false;
    }
    return true;
  };

  const isFormValid = async () => {
    const isCurrentPassValid = validateCurrentPass();
    const isNewPassValid = validateNewPass();
    const isAgainPassValid = validateAgainPass();
    return isCurrentPassValid && isNewPassValid && isAgainPassValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = await isFormValid();
    if (!isValid || loading) {
      return false;
    }

    const passwordData = {
      old_password: currentPassword,
      new_password1: password,
      new_password2: passwordAgain,
    };
    setLoading(true);

    changePassword(passwordData)
      .then(() => {
        setLoading(false);
        Notification('success', __('Password changed'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot change user password', passwordData);
        setLoading(false);
        Notification('error', __('Password not changed'), __('There was an error while saving your changes'));
      });
    return true;
  };

  return (
    <Page title={__('Change password')}>
      <Prompt
        message={__('By leaving this form you will lose all progress. Are you sure you want to leave?')}
        when={dirty}
      />
      <form onSubmit={handleSubmit}>
        <DescriptionCard size="sm" theme="info">
          <div className="ChangePasswordContainer">
            {`${__('Password should')}:`}
            <ul>
              <li>{__('be at least 8 characters long')}</li>
              <li>{__('contain at least 1 lowercase letter')}</li>
              <li>{__('contain at least 1 uppercase letter')}</li>
              <li>{__('contain at least 1 number')}</li>
            </ul>
          </div>
        </DescriptionCard>
        <Label inputId="current-password" text={__('Current password')} />
        <PasswordInput
          disabled={loading}
          id="current-password"
          value={currentPassword.value}
          error={currentPasswordError}
          handleChange={(val) => {
            setDirty(true);
            setCurrentPassword(val);
            debouncedValidateCurrentPassword(val).then(err => setCurrentPasswordError(err));
          }}
        />
        <Label inputId="password" text={__('New password')} />
        <PasswordInput
          disabled={loading}
          id="password"
          value={password}
          error={passwordError}
          handleChange={(val) => {
            setDirty(true);
            setPassword(val);
            debouncedValidatePassword(val).then(err => setPasswordError(err));
          }}
        />
        <Label inputId="password-again" text={__('Password again')} />
        <PasswordInput
          disabled={loading}
          id="password-again"
          value={passwordAgain}
          error={passwordAgainError}
          handleChange={(val) => {
            setDirty(true);
            setPasswordAgain(val);
            debouncedValidatePasswordAgain(password, val).then(err => setPasswordAgainError(err));
          }}
        />
        <Button
          disabled={loading}
          theme="success"
          type="submit"
        >
          {loading ? __('Saving') : __('Change password')}
        </Button>
      </form>
    </Page>
  );
};

export default ChangePasswordContainer;
