import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { changeDisableStaff } from 'src/account/actions';
import {
  Label, Notification, Switcher,
} from 'shared/components';
import './styles.scss';

const DebugSwitcher = ({
  changeDisableStaffAction,
  isStaffDisabled,
  user,
  forceRerender,
}) => {
  const isStaff = get(user, 'is_staff');

  if (!user || !isStaff) {
    return null;
  }

  const handleSwitcherChange = () => {
    const debugState = !isStaffDisabled;
    changeDisableStaffAction(debugState);
    forceRerender();

    if (debugState) {
      Notification('success', 'Debug state enabled', 'All staff plan feature overrides are deactivated');
    } else {
      Notification('success', 'Debug state disabled', 'All staff plan feature overrides are active');
    }
  };

  return (
    <div className="DebugSwitcher">
      <Label
        text={__('Staff disabled')}
        inputId="debug-switch"
        description="This will disable staff overrides regarding pricing plan features"
      />
      <Switcher
        isFeatureEnabled
        checked={isStaffDisabled}
        handleChange={handleSwitcherChange}
        size="sm"
      />
    </div>
  );
};

DebugSwitcher.propTypes = {
  changeDisableStaffAction: PropTypes.func.isRequired,
  forceRerender: PropTypes.func.isRequired,
  isStaffDisabled: PropTypes.bool,
  user: PropTypes.object,
};

DebugSwitcher.defaultProps = {
  isStaffDisabled: false,
  user: null,
};

const mapStateToProps = state => ({
  user: get(state, 'account.user'),
  isStaffDisabled: get(state, 'account.disableStaff'),
});

const mapDispatchToProps = {
  changeDisableStaffAction: changeDisableStaff,
};

export default connect(mapStateToProps, mapDispatchToProps)(DebugSwitcher);
