import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Raven from 'raven-js';
import { sendErrorReport } from 'shared/helpers';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Dropdown,
  IconUser,
  Notification,
  DebugSwitcher,
} from 'shared/components';
import { logout, logoutError, logoutSuccess } from 'src/account/actions';
import './styles.scss';

const ProfileNavigation = ({
  forceRerender,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const popupRef = useRef();

  const [isMenuDisplayed, setMenuDisplay] = useState(false);
  const [logoutDisabled, setLogoutDisabled] = useState(false);

  const handleLogout = () => {
    setLogoutDisabled(true);

    dispatch(logout())
      .then(() => {
        dispatch(logoutSuccess());
        Notification('success', __('You have been logged out'));
        history.push('/login');
        Raven.setUserContext();
      })
      .catch((err) => {
        setLogoutDisabled(false);
        sendErrorReport(err, 'Cannot logout user');
        dispatch(logoutError());
        Notification('error', __('Error occured'), __('We could not log you out'));
      });
  };

  return (
    <div className="ProfileNavigation">
      <div className="profile-label">
        <div className="sidebar-item" onClick={() => setMenuDisplay(!isMenuDisplayed)} role="presentation">
          <IconUser color="#fff" />
        </div>
      </div>
      {isMenuDisplayed && (
        <Dropdown ref={popupRef} close={() => setMenuDisplay(!isMenuDisplayed)}>
          <ul className="links">
            <DebugSwitcher forceRerender={forceRerender} />
            <li>
              <NavLink className="li-content" onClick={() => setMenuDisplay(false)} to="/profile">
                {__('My profile')}
              </NavLink>
            </li>
            <li>
              <button
                className="li-content"
                onClick={handleLogout}
                disabled={logoutDisabled}
                type="button"
              >
                {__('Logout')}
              </button>
            </li>
          </ul>
        </Dropdown>
      )}
    </div>
  );
};

ProfileNavigation.propTypes = {
  forceRerender: PropTypes.func.isRequired,
};

export default ProfileNavigation;
