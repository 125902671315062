import React, { useState } from 'react';
import Raven from 'raven-js';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Notice,
  Notification,
  Subtitle,
} from 'shared/components';
import { sendErrorReport } from 'shared/helpers';
import { logout, logoutError, logoutSuccess } from 'src/account/actions';
import { ReactComponent as TwofaImage } from '../SecurityContainer/twofa-image.svg';
import TwofaSetupForm from '../SecurityContainer/TwofaSetupForm';
import './styles.scss';

const Login2FASetup = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showTwofaForm, setTwofaFormDisplay] = useState(false);
  const [logoutDisabled, setLogoutDisabled] = useState(false);

  const handleLogout = () => {
    setLogoutDisabled(true);

    dispatch(logout())
      .then(() => {
        dispatch(logoutSuccess());
        Notification('success', __('You have been logged out'));
        history.push('/login');
        Raven.setUserContext();
      })
      .catch((err) => {
        setLogoutDisabled(false);
        sendErrorReport(err, 'Cannot logout user');
        dispatch(logoutError());
        Notification('error', __('Error occured'), __('We could not log you out'));
      });
  };

  return (
    <div className="Login2FASetup">
      <div className="Login2FASetup-wrapper">
        <div className="ls-logo-cont">
          <img src="/img/ls-logo.svg" alt="logo" height="80px" />
        </div>
        <div className="section-header">
          <Subtitle text={__('Two-factor authentication')} />
        </div>
        <div className="section-body">
          <div className="description">
            {__('Two factor authentication adds an extra layer of security to your account by asking for a verification code when you sign in.')}
            <Notice theme="error" size="sm">
              <div>
                {__('Two-factor authentication is required in your company account.')}
                &nbsp;
                {__('You have to setup the two-factor authentication before accessing the platform.')}
              </div>
            </Notice>
          </div>
          <div className="image-container"><TwofaImage /></div>
          <div>
            <ul>
              <li>{__('Log in using your email and password as usual.')}</li>
              <li>{__('Using a two-factor authentication app, generate a passcode to verify your identity.')}</li>
              <li>{__('Once you enter the passcode at the prompt, you will be logged in to the platform.')}</li>
            </ul>
          </div>
          <Button
            theme="success"
            onClick={() => setTwofaFormDisplay(true)}
          >
            {__('Setup 2FA authentication')}
          </Button>
          <div className="logout">
            <Button
              theme="default"
              onClick={handleLogout}
              disabled={logoutDisabled}
            >
              {__('Logout')}
            </Button>
          </div>
        </div>
      </div>
      {showTwofaForm && (
        <TwofaSetupForm closeCb={() => setTwofaFormDisplay(false)} />
      )}
    </div>
  );
};

export default Login2FASetup;
