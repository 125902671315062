/* eslint-disable camelcase */
import { get, isArray, sortBy } from 'lodash';
import Raven from 'raven-js';
import moment from 'moment';
import {
  defaultDateTimeFormat,
  lettersRegex,
  numberRegex,
  initialLicenseListColumns,
  initialOrderListColumns,
  initialOrderListSort,
  initialLicenseListSort,
  initialDevicesListSort,
  initialLicenseListSize,
  initialOrderListSize,
  consumptionPeriods,
  billingCycles,
  overageTypes,
  platformFeatures,
  eventsConfig,
  managerPermissionDescription,
  stripeTaxIDs,
  licenseAPIactions,
} from 'shared/constants';
import { countriesList } from 'shared/countriesAlpha2Code';
import store from 'src/store';

export const getLocale = (navigator) => {
  if (!navigator) {
    return null;
  }

  if (navigator.languages && navigator.languages.length && navigator.languages.length > 0) {
    return navigator.languages[0];
  }

  if (navigator.language) {
    return navigator.language;
  }

  if (navigator.browserLanguage) {
    return navigator.browserLanguage;
  }

  return null;
};

export const getLanguageFromLocale = (locale) => {
  if (locale) {
    return locale.split('-')[0].toLowerCase();
  }
  return 'en';
};

export const slugify = (t, separator) => {
  let text = t.toString().toLowerCase().trim();

  const sets = [
    { to: 'a', from: '[ÀÁÂÃÄÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
    { to: 'c', from: '[ÇĆĈČ]' },
    { to: 'd', from: '[ÐĎĐÞ]' },
    { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
    { to: 'g', from: '[ĜĞĢǴ]' },
    { to: 'h', from: '[ĤḦ]' },
    { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
    { to: 'j', from: '[Ĵ]' },
    { to: 'ij', from: '[Ĳ]' },
    { to: 'k', from: '[Ķ]' },
    { to: 'l', from: '[ĹĻĽŁ]' },
    { to: 'm', from: '[Ḿ]' },
    { to: 'n', from: '[ÑŃŅŇ]' },
    { to: 'o', from: '[ÒÓÔÕÖØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
    { to: 'oe', from: '[Œ]' },
    { to: 'p', from: '[ṕ]' },
    { to: 'r', from: '[ŔŖŘ]' },
    { to: 's', from: '[ßŚŜŞŠ]' },
    { to: 't', from: '[ŢŤ]' },
    { to: 'u', from: '[ÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
    { to: 'w', from: '[ẂŴẀẄ]' },
    { to: 'x', from: '[ẍ]' },
    { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
    { to: 'z', from: '[ŹŻŽ]' },
    { to: '-', from: '[·/_,:;\']' },
  ];

  sets.forEach((set) => {
    text = text.replace(new RegExp(set.from, 'gi'), set.to);
  });

  text = text.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

  if ((typeof separator !== 'undefined') && (separator !== '-')) {
    text = text.replace(/-/g, separator);
  }

  return text;
};

export const isValidDate = (dateString, isOptional = false) => {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;

  if (isOptional && !dateString) {
    return true;
  }

  if (typeof dateString !== 'string') {
    return false;
  }

  if (!dateString.match(regEx)) {
    return false;
  }
  const d = new Date(dateString);
  if (Number.isNaN(d.getTime())) {
    return false;
  }
  return d.toISOString().slice(0, 10) === dateString;
};

export const buildCustomErrorMeta = (err, requestData = undefined) => ({
  data: get(err, 'response.data'),
  errorMessage: get(err, 'message'),
  headers: get(err, 'response.headers'),
  statusText: get(err, 'response.statusText'),
  requestData,
});

export const getLicenseStatus = (enabled, active) => {
  if (!enabled) {
    return {
      positive: false,
      message: 'Disabled',
    };
  }
  return {
    positive: active,
    message: active ? 'Active' : 'Inactive',
  };
};

export const copyText = (val) => {
  if (!val) {
    return false;
  }
  const textField = document.createElement('textarea');
  textField.innerText = val;
  document.body.appendChild(textField);
  textField.select();
  const result = document.execCommand('copy');
  textField.remove();
  return result;
};

export const isJSON = (val) => {
  try {
    JSON.parse(val);
  } catch (e) {
    return false;
  }
  return true;
};

export const isJSONObject = (val) => {
  try {
    const obj = JSON.parse(val);
    return typeof obj === 'object';
  } catch (e) {
    return false;
  }
};

export const parseObject = (val, fallback = undefined) => {
  let data = {};

  if (!val || !isJSON(val)) {
    return fallback || data;
  }

  const result = JSON.parse(val);
  if (typeof result === 'object' && !Array.isArray(result)) {
    data = result;
  }

  return data;
};

export const parseMetadata = (val, fallback = '') => {
  if (!val) {
    return fallback;
  }
  if (typeof val === 'object') {
    return JSON.stringify(val);
  }

  if (!val || !isJSONObject(val)) {
    return fallback;
  }

  if (!Object.keys(val).length) {
    return fallback;
  }

  return val;
};

export const getErrMsg = (err, field = 'message') => {
  const data = get(err, 'response.data');
  const dataJson = parseObject(data, data);
  const message = get(dataJson, `${field}`) || get(dataJson, 'detail');

  if (!message) {
    if (typeof data === 'string') {
      return data;
    }
    return '';
  }
  return message;
};

export const formatDate = (val, formatType = defaultDateTimeFormat, fallback = '-') => {
  const isValid = moment(val).isValid();

  if (!val || !isValid) {
    return fallback;
  }
  return moment(val).format(formatType);
};

export const displayValue = (val, fallback = '-') => {
  if (!val && val !== 0) {
    return fallback;
  }
  return val;
};

export const makeProductDictionary = (products) => {
  const dict = {};
  if (!products) {
    return dict;
  }
  if (Array.isArray(products)) {
    products.forEach((product) => {
      dict[get(product, 'id')] = get(product, 'product_name');
    });
  }
  return dict;
};

export const getNumbersFromString = (value, fallback = '') => {
  if (!value || typeof value !== 'string') {
    return fallback;
  }
  const numbersFromString = value.match(numberRegex);

  if (!numbersFromString || numbersFromString.length === 0) {
    return fallback;
  }

  return numbersFromString.join();
};

export const getCharsFromString = (value, fallback = '') => {
  if (!value || typeof value !== 'string') {
    return fallback;
  }
  const charsFromString = value.match(lettersRegex);

  if (!charsFromString || charsFromString.length === 0) {
    return fallback;
  }

  return charsFromString.join();
};

export const mapProductsToSelector = (products, value = 'product_name') => {
  const productsOptions = [];
  if (!products) {
    return productsOptions;
  }
  if (Array.isArray(products)) {
    products.forEach((product) => {
      const option = {
        label: get(product, 'product_name'),
        value: get(product, `${value}`),
        data: product,
      };
      productsOptions.push(option);
    });
  }
  return productsOptions;
};

export const mapFeaturesToSelector = (features) => {
  const featureOptions = [];

  if (Array.isArray(features)) {
    features.forEach((feature) => {
      const option = {
        label: get(feature, 'name'),
        value: get(feature, 'id'),
        code: get(feature, 'code'),
        name: get(feature, 'name'),
        feature_type: get(feature, 'feature_type'),
        expiry_date: get(feature, 'expiry_date') || null,
        max_consumption: get(feature, 'max_consumption'),
        allow_unlimited_consumptions: get(feature, 'allow_unlimited_consumptions'),
        allow_overages: get(feature, 'allow_overages'),
        max_overages: get(feature, 'max_overages'),
        reset_consumption: get(feature, 'reset_consumption'),
        consumption_period: get(feature, 'consumption_period'),
        is_floating: get(feature, 'is_floating'),
        is_floating_cloud: get(feature, 'is_floating_cloud'),
        floating_timeout: get(feature, 'floating_timeout'),
        floating_users: get(feature, 'floating_users'),
        metadata: get(feature, 'metadata') || '',
        data: feature,
      };
      featureOptions.push(option);
    });
  }
  return sortBy(featureOptions, 'label');
};

export const formatProductFeatures = featuresList => featuresList.map(f => ({
  product_feature: get(f, 'product_feature.code'),
  max_consumption: Number(get(f, 'max_consumption')),
  allow_unlimited_consumptions: get(f, 'allow_unlimited_consumptions') || false,
  allow_overages: get(f, 'allow_overages') || false,
  max_overages: get(f, 'max_overages') || 0,
  reset_consumption: get(f, 'reset_consumption') || false,
  consumption_period: get(f, 'consumption_period') || 'monthly',
  total_consumptions: Number(get(f, 'total_consumptions')) || 0,
  expiry_date: get(f, 'expiry_date') || null,
  metadata: get(f, 'metadata') || '',
  is_floating: get(f, 'is_floating'),
  is_floating_cloud: get(f, 'is_floating_cloud'),
  floating_timeout: get(f, 'floating_timeout'),
  floating_users: get(f, 'floating_users'),
  name: get(f, 'product_feature.name'),
  code: get(f, 'product_feature.code'),
  id: get(f, 'id'),
}));

export const getTableColumnValues = (columns) => {
  let columnValues = [];
  if (Array.isArray(columns)) {
    columnValues = columns.map(obj => obj.value);
  }
  return columnValues.filter(Boolean);
};

export const getTableColumnObjects = (columnValues, allColumnObjects) => {
  const columns = [];
  if (Array.isArray(columnValues) && Array.isArray(allColumnObjects)) {
    columnValues.forEach((val) => {
      const col = allColumnObjects.filter(obj => obj.value === val);
      columns.push(...col);
    });
  }
  return columns.filter(Boolean);
};

export const getTableCustomerName = (data, fallback = '-') => {
  const firstName = get(data, 'customer.first_name') || '';
  const lastName = get(data, 'customer.last_name') || '';

  if (!firstName && !lastName) {
    return fallback;
  }

  if (!!firstName && !lastName) {
    return `${firstName}`;
  }

  if (!firstName && !!lastName) {
    return `${lastName}`;
  }

  const name = `${lastName}, ${firstName}`;
  return name.trim();
};

export const displayFeatureList = (features, fallback = '-') => {
  if (!features || features.length === 0) {
    return fallback;
  }

  if (Array.isArray(features)) {
    const list = features.map(f => f.name).filter(Boolean).join(', ');
    return list || fallback;
  }

  return fallback;
};

export const isObject = val => (
  val === Object(val) && typeof val !== 'function' && !Array.isArray(val)
);

export const getInitialUiOptions = () => ({
  list_columns: {
    licenses: getTableColumnValues(initialLicenseListColumns),
    orders: getTableColumnValues(initialOrderListColumns),
  },
  list_sort: {
    licenses: initialLicenseListSort,
    orders: initialOrderListSort,
    devices: initialDevicesListSort,
  },
  list_size: {
    licenses: initialLicenseListSize,
    orders: initialOrderListSize,
  },
  misc: {
    subscription_type_notice_hide: false,
  },
});

export const isFeatureEnabled = (feature, features) => {
  const state = store.getState();
  const featureList = features || get(state, 'company.features', []);
  const isSuperUser = get(state, 'account.user.is_staff');
  const isStaffDisabled = get(state, 'account.disableStaff');
  const actualFeature = isArray(featureList) ? featureList.find(f => f.key === feature) : undefined;

  // Staff user should have all features enabled
  if (isSuperUser && !isStaffDisabled) {
    return true;
  }

  // Check if feature exists
  if (!get(actualFeature, 'is_active')) {
    return false;
  }

  // Feature is enabled
  return true;
};

export const isFeatureAvailable = (feature, value = 0, features) => {
  const state = store.getState();
  const featureList = features || get(state, 'company.features', []);
  const actualFeature = featureList.find(f => f.key === feature);
  const isSuperUser = get(state, 'account.user.is_staff');
  const isStaffDisabled = get(state, 'account.disableStaff');

  // Staff user should have all features enabled
  if (isSuperUser && !isStaffDisabled) {
    return true;
  }

  // Check if feature exists
  if (!actualFeature) {
    return false;
  }

  if (actualFeature.value === undefined) {
    return false;
  }

  // Check if feature has infinite usage
  if (actualFeature.value === -1) {
    return true;
  }

  // Check if usage has depleted usage
  if (
    typeof actualFeature.value === 'number' &&
    value >= actualFeature.value
  ) {
    return false;
  }

  return true;
};

export const isFeatureAllowed = (feature) => {
  const state = store.getState();
  const isSuperUser = get(state, 'account.user.is_staff');
  const isStaffDisabled = get(state, 'account.disableStaff');
  const featureEnabled = isFeatureEnabled(feature);
  const featureAvailable = isFeatureAvailable(feature);

  // Staff user should have all features enabled
  if (isSuperUser && !isStaffDisabled) {
    return true;
  }

  return featureEnabled && featureAvailable;
};

export const checkUserPermission = (permission = '') => {
  const state = store.getState();
  const user = get(state, 'account.user');
  const userPermissions = get(state, 'company.userPermissions');
  const company = get(state, 'company.details');
  const isStaff = get(user, 'is_staff');
  const companyID = get(company, 'id');

  if (isStaff) {
    return true;
  }
  const isUserCompanyAdmin = get(user, `is_admin.${companyID}`);
  if (isUserCompanyAdmin) {
    return true;
  }
  const hasPermission = userPermissions.includes(permission);
  return hasPermission;
};

export const getProductNumber = () => {
  const state = store.getState();
  const products = get(state, 'products.list') || [];
  const activeProducts = products.filter(p => !p.is_archived);
  return activeProducts.length;
};

export const isEligableForStandardPlan = () => {
  const state = store.getState();
  const currentCompany = get(state, 'company');
  const passedTrialDays = moment().diff(moment(currentCompany.details.trial_start), 'days') || 0;
  return passedTrialDays < 15 || currentCompany.details.payment_card_id;
};

export const checkTrial = () => {
  const state = store.getState();
  const currentCompany = get(state, 'company');
  const trialStart = get(currentCompany, 'details.trial_start') || null;
  const trialDaysValue = get(currentCompany, 'details.trial_days');
  let trialDays;

  if (trialDaysValue === 0) {
    trialDays = 0;
  } else if (!trialDaysValue) {
    trialDays = 15;
  } else {
    trialDays = trialDaysValue;
  }

  // handle enterprise companies in trial mode
  if (trialStart === null) {
    return {
      isTrial: false,
      remaining_days: null,
      passed_trial_days: null,
      trial_expired: null,
    };
  }

  const passedTrialDays = moment().diff(moment(trialStart), 'days') || 0;
  const trialEnd = moment(trialStart).add(trialDays, 'days');

  return {
    isTrial: passedTrialDays < trialDays,
    remaining_days: trialDays - passedTrialDays,
    passed_trial_days: passedTrialDays,
    trial_expired: passedTrialDays >= trialDays,
    trialEnd: trialEnd.format(),
  };
};

export const getDisabledMessage = () => {
  const state = store.getState();
  const currentCompany = get(state, 'company');

  if (!currentCompany) {
    return '';
  }

  const trialConfig = checkTrial();


  if (get(currentCompany, 'details.inactive_from')) {
    return 'Your account is set as inactive. Please check if your subscription is active.';
  }

  if (!trialConfig.isTrial) {
    return 'Your trial period has expired, please add payment method in order to continue using LicenseSpring';
  }

  return '';
};

export const mapCountriesToSelector = (countries) => {
  const countriesOptions = [];
  if (!countries) {
    return countriesOptions;
  }
  if (Array.isArray(countries)) {
    countries.forEach((c) => {
      const option = {
        label: get(c, 'name'),
        value: get(c, 'name'),
        data: c,
      };
      countriesOptions.push(option);
    });
  }
  return countriesOptions;
};

export const mapRegionsToSelector = (regions) => {
  const regionsOptions = [];
  if (!regions) {
    return regionsOptions;
  }
  if (Array.isArray(regions)) {
    regions.forEach((r) => {
      const option = {
        label: get(r, 'name'),
        value: get(r, 'shortCode'),
        data: r,
      };
      regionsOptions.push(option);
    });
  }
  return regionsOptions;
};

export const getPublicStripeApiKey = () => {
  const apiKey = process.env.REACT_APP_STRIPE_KEY;
  return apiKey;
};

export const getConsumptionPeriod = (val) => {
  const selected = consumptionPeriods.find(cp => cp.value === val);

  if (!val || !selected) {
    return consumptionPeriods[0];
  }

  return selected;
};

export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return undefined;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatAmountValue = (val, fallback = '-') => {
  if (!val) {
    if (val === 0) {
      return val;
    }
    return fallback;
  }

  const stringNum = (val).toString();
  const formatted = `${stringNum.substring(0, stringNum.length - 2)}.${stringNum.substring(stringNum.length - 2)}`;

  return formatted;
};


export const getSelectedBillingCycleLabel = (cycleVal) => {
  const selected = billingCycles.find(c => c.value === cycleVal);
  return get(selected, 'label');
};

export const displayMaxOveragesValue = (data) => {
  const allowOverages = get(data, 'allow_overages');
  const maxOverages = get(data, 'max_overages');

  if (!allowOverages) {
    return '-';
  }

  if (maxOverages === 0) {
    return 'Unlimited';
  }

  return displayValue(maxOverages);
};

export const setDefaultOverageType = (product) => {
  const maxOverage = get(product, 'max_overages');

  if (maxOverage === 0) {
    return overageTypes.unlimited;
  }

  return overageTypes.custom;
};

export const mapCurrencySymbol = (val) => {
  if (val === 'usd') {
    return '$';
  }
  if (val === 'cad') {
    return 'CAD$';
  }
  if (val === 'eur') {
    return '€';
  }
  if (val === 'gbp') {
    return '£';
  }

  return val;
};

export const getLSEnterpriseProductId = () => {
  const customEnv = process.env.REACT_APP_ENV;
  const testId = 'prod_Gg87kmv17F8xfD';
  const prodId = 'prod_Gg8BQ0o81oZsXt';

  if (
    customEnv === 'production'
  ) {
    return prodId;
  }
  return testId;
};

export const getSelectedPricingPlan = (companyPricingPlan, planList = []) => {
  if (!companyPricingPlan) {
    return '-';
  }

  const selectedPricingPlan = planList.filter(c => c.id === companyPricingPlan);
  if (!selectedPricingPlan) {
    return '-';
  }

  return get(selectedPricingPlan, '[0]');
};

export const displayCouponName = (companyCoupon, couponsList = []) => {
  if (!companyCoupon) {
    return '-';
  }

  const selectedCoupon = couponsList.filter(c => c.id === companyCoupon);
  if (!selectedCoupon) {
    return '-';
  }

  return get(selectedCoupon, '[0].name');
};

export const displayTaxRate = (companyTaxRate, taxRatesList = []) => {
  if (!companyTaxRate) {
    return '-';
  }

  const selectedTaxRate = taxRatesList.filter(c => c.id === companyTaxRate);
  if (!selectedTaxRate) {
    return '-';
  }

  return `${get(selectedTaxRate, '[0].display_name')} ${get(selectedTaxRate, '[0].jurisdiction')} - ${get(selectedTaxRate, '[0].percentage')}%`;
};

export const generatePass = (pLength = 8) => {
  const keyListAlpha = 'abcdefghijklmnopqrstuvwxyz';
  const keyListInt = '123456789';
  const keyListSpec = '!@#_';
  let password = '';

  let len = Math.ceil(pLength / 2);
  len -= 1;
  const lenSpec = pLength - 2 * len;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    password += keyListAlpha.charAt(Math.floor(Math.random() * keyListAlpha.length));
    password += keyListInt.charAt(Math.floor(Math.random() * keyListInt.length));
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < lenSpec; i++) { password += keyListSpec.charAt(Math.floor(Math.random() * keyListSpec.length)); }

  password = password.split('').sort(() => 0.5 - Math.random()).join('');

  return password;
};

export const filterCompanies = (companies, query) => {
  if (!companies || !Array.isArray(companies)) {
    return [];
  }
  if (!query) {
    return companies;
  }

  return companies.filter((company) => {
    const companyName = company.name;
    if (!companyName) {
      return false;
    }
    return companyName.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  });
};

export const mapCustomerLabelsToSelector = (labels = []) => {
  if (Array.isArray(labels)) {
    return labels.map(l => ({
      value: l.id,
      label: l.label,
      data: l,
    }));
  }
  return [];
};

export const sendErrorReport = (err, msg, data = undefined) => {
  Raven.captureMessage(msg, {
    extra: buildCustomErrorMeta(err, data),
  });
};

export const mapCustomFieldsToSelector = (product) => {
  const fieldsOptions = [];
  const customFields = get(product, 'custom_fields') || [];

  if (Array.isArray(customFields)) {
    customFields.forEach((cf) => {
      const option = {
        label: get(cf, 'name'),
        value: get(cf, 'id'),
        cf_value: get(cf, 'default_value'),
        data: cf,
      };
      fieldsOptions.push(option);
    });
  }
  return fieldsOptions;
};

export const removeForbiddenCompanies = (user, companies) => {
  const { is_active_on_company, is_staff } = user;
  if (is_staff) {
    return companies;
  }

  const list = [];
  companies.forEach((comp) => {
    const { id } = comp;
    if (is_active_on_company[id]) {
      list.push(comp);
    }
  });

  return list;
};

export const getUpcomingInvoiceDate = (companyDetails) => {
  const nextPaymentAttempt = get(companyDetails, 'upcoming_invoice.next_payment_attempt');
  const nextPaymentDate = get(companyDetails, 'upcoming_invoice.date');

  return nextPaymentAttempt || nextPaymentDate;
};

export const getMonthlyRateLimit = () => {
  const state = store.getState();
  const planType = get(state, 'company.details.plan_type') || 'free';
  const companyFeatures = get(state, 'company.features') || [];

  const monthlyRateLimitFeature = companyFeatures.find(cf => cf.key === platformFeatures.usage_mrl);
  const monthlyRateLimit = get(monthlyRateLimitFeature, 'value');

  if (monthlyRateLimit) return monthlyRateLimit;

  // fallback
  if (planType === 'standard') return 100000;
  if (planType === 'enterprise') return 5000;
  return 5000;
};

export const getData = (dataObject, property, fallbackData) => {
  const data = get(dataObject, `${property}`);
  if (!data) {
    return get(fallbackData, `${property}`);
  }
  return data;
};

export const getPortalData = (portalData, property, fallbackData, fallback = false) => {
  try {
    // eslint-disable-next-line no-prototype-builtins
    const doesPropExist = typeof portalData === 'object' && portalData.hasOwnProperty(property);

    if (doesPropExist) {
      return portalData[property];
    }
    return fallbackData[property] || fallback;
  } catch (error) {
    return fallbackData[property] || fallback;
  }
};

export const formatAnalyticsData = (data, isDaily) => data.map(d => ({
  ...d,
  ts: isDaily ? moment(d.ts__date).unix() * 1000 : moment(d.ts).unix() * 1000,
}));

export const getDefaultLicensePolicy = (products, productToEdit, field = 'is_default') => {
  let product;
  if (productToEdit) {
    product = productToEdit;
  } else {
    product = get(products, '[0]');
  }
  if (get(product, 'selected_license_policy')) {
    return get(product, 'selected_license_policy');
  }
  const productPolicies = get(product, 'license_templates') || [];
  const defaultPolicy = productPolicies.find(p => p[field]);
  return defaultPolicy;
};

export const formatPolicyFeatures = (policyFeatures = []) => policyFeatures.map(pf => ({
  ...pf,
  name: get(pf, 'product_feature.name'),
  code: get(pf, 'product_feature.code'),
  label: get(pf, 'product_feature.name'),
  value: get(pf, 'product_feature.id'),
}));

export const formatPolicies = (policies = []) => policies.map(p => ({
  ...p,
  label: get(p, 'name'),
  value: get(p, 'code'),
}));

export const formatPolicyCFields = (cfields = []) => cfields.map(pf => ({
  ...pf,
  label: get(pf, 'product_custom_field.name'),
  value: get(pf, 'product_custom_field.id'),
  cf_value: get(pf, 'default_value'),
}));

export const mapPolicyProductFeatures = (product, policy) => {
  const productFeatures = mapFeaturesToSelector(get(product, 'product_features') || []);
  const policyFeatures = formatPolicyFeatures(get(policy, 'license_product_feature_templates') || []);
  const list = productFeatures.map((pf) => {
    const isPolicyFeature = policyFeatures.find(i => i.value === pf.value);
    if (isPolicyFeature) {
      return isPolicyFeature;
    }
    return pf;
  });
  return list;
};

export const mapPolicyCustomFields = (product, policy) => {
  const productCFields = mapCustomFieldsToSelector(product);
  const policyCFields = formatPolicyCFields(get(policy, 'license_custom_field_templates') || []);
  const list = productCFields.map((pf) => {
    const isPolicyFeature = policyCFields.find(i => i.value === pf.value);
    if (isPolicyFeature) {
      return isPolicyFeature;
    }
    return pf;
  });
  return list;
};

export const showLicenseUsers = (users = []) => {
  if (!users) {
    return '';
  }
  if (Array.isArray(users)) {
    return users.join(', ');
  }
  return users.split(',').join(', ');
};

export const formatEventsToSelector = (events = []) => {
  if (Array.isArray(events)) {
    return events.map(e => ({
      value: e.id,
      label: get(eventsConfig, `${e.code}.label`) || '',
      data: e,
    }));
  }
  return [];
};

export const formatEmailVariablesToSelector = (variables = []) => {
  if (Array.isArray(variables)) {
    return variables
      .filter(v => v.code !== 'license_list' && v.code !== 'user_portal')
      .map(v => ({
        value: v.code,
        label: v.label,
        data: v,
      }));
  }
  return [];
};

export const isAllowedEventRecipient = (notificationEvent, recipientCode) => {
  const { data } = notificationEvent;
  const eventCode = get(data, 'code');
  if (!eventsConfig[eventCode]) { return false; }

  const eventAllowedRecipients = get(eventsConfig, `${eventCode}.allowed_recipients`) || [];
  const isAllowed = eventAllowedRecipients.includes(recipientCode);
  return isAllowed;
};

export const hasCustomEventData = (notificationEvent, code) => {
  const { data } = notificationEvent;
  const eventCode = get(data, 'code');
  if (!eventsConfig[eventCode]) { return false; }

  const customData = eventsConfig[eventCode].custom_data;
  if (!code) {
    return !!customData;
  }
  const hasData = customData ? customData.includes(code) : false;
  return hasData;
};

export const joinArrayItems = (list = [], value) => {
  if (!Array.isArray(list)) {
    return '-';
  }
  if (!list.length) { return '-'; }
  const arrItems = list.map((i) => {
    if (!value) { return i; }
    return i[value];
  });
  return arrItems.join(', ');
};

export const formatManagerPermissions = (list) => {
  const data = list.map((i) => {
    const label = i.code.split('_').join(' ');
    const desc = managerPermissionDescription[i.code];
    return ({
      ...i,
      value: i.code,
      label: capitalizeFirstLetter(label),
      desc,
    });
  });
  return data;
};

export const mapPoliciesForSelector = (list) => {
  if (!list || !Array.isArray(list)) {
    return [];
  }
  return list.filter(Boolean).map(l => ({
    ...l,
    value: l.id,
    label: l.product_code ? `[${l.product_code}] ${l.name}` : l.name,
    name: l.name,
  }));
};

export const formatTaxTypeCode = code => code.toUpperCase().split('_').join(' ');

export const mapTaxOptionsToSelector = options => (
  options.map(o => ({
    ...o,
    label: `${formatTaxTypeCode(o.code)} ${o.label} (${o.country})`,
    value: `${o.code}-${o.country}`,
    error: '',
    number: '',
  }))
);

export const getCustomerTaxOptions = (list) => {
  const result = [];
  list.forEach((i) => {
    const stripeTax = stripeTaxIDs.filter(s => get(s, 'code') === get(i, 'type'));

    if (stripeTax.length === 1) {
      result.push(stripeTax[0]);
    }
    if (stripeTax.length > 1) {
      const country = countriesList.find(c => c.code === get(i, 'country'));
      if (!country) {
        result.push(stripeTax[0]);
      }
      const countryTax = stripeTax.find(st => get(st, 'country') === get(country, 'name'));
      if (!countryTax) {
        result.push(stripeTax[0]);
      }
      result.push(countryTax);
    }
  });
  const listOptions = mapTaxOptionsToSelector(result);
  const listOptionsWithValue = listOptions.map((lo) => {
    const found = list.find(li => get(li, 'type') === get(lo, 'code'));
    if (!found) {
      return lo;
    }
    return ({
      ...lo,
      number: get(found, 'value'),
    });
  });
  return listOptionsWithValue;
};

export const getLicensePolicyFile = (policy) => {
  const mappedPolicy = { ...policy };
  mappedPolicy.product_features = policy.license_product_feature_templates.map((pf) => {
    const feat = {
      code: pf.product_feature.code,
      name: pf.product_feature.name,
      feature_type: pf.feature_type,
      expiry_date: pf.expiry_date || null,
      metadata: pf.metadata || {},
      is_floating: get(pf, 'is_floating'),
      is_floating_cloud: get(pf, 'is_floating_cloud'),
      floating_timeout: get(pf, 'floating_timeout'),
      floating_users: get(pf, 'floating_users'),
    };
    if (pf.feature_type === 'consumption') {
      feat.max_consumption = pf.max_consumption;
      feat.total_consumptions = pf.total_consumptions || 0;
      feat.allow_overages = pf.allow_overages;
      feat.max_overages = pf.max_overages || 0;
      feat.reset_consumption = pf.reset_consumption;
      feat.consumption_period = pf.consumption_period;
    }
    return feat;
  });
  mappedPolicy.custom_fields = policy.license_custom_field_templates.map(cf => ({
    name: cf.name,
    data_type: cf.data_type,
    value: cf.default_value,
  }));
  delete mappedPolicy.license_product_feature_templates;
  delete mappedPolicy.license_custom_field_templates;
  const policyData = encodeURIComponent(JSON.stringify(mappedPolicy));
  const data = unescape(policyData);
  download(`data:text/plain;charset=utf-8,${btoa(data)}`, `${get(policy, 'name') || 'air_gapped_license_policy'}.lic`, 'text/plain');
};

export const getPolicyHasMaxTransfers = (policy) => {
  const maxTransfers = get(policy, 'max_transfers');
  if (!maxTransfers || maxTransfers === 0) { return false; } // no limit
  if (Number(maxTransfers) < 0) { return false; } // prevent device transfer
  if (Number(maxTransfers) > 0) {
    return true;
  }
  return false;
};

export const objectToQueryParams = (obj) => {
  let str = '';

  if (!isObject(obj)) {
    return str;
  }

  Object.keys(obj).forEach((key) => {
    if (str !== '') {
      str += '&';
    }
    str += `${key}=${encodeURIComponent(obj[key])}`;
  });
  return str;
};

export const filenameFromHeadersData = (headers) => {
  const contentDisposition = headers['content-disposition'];

  if (!contentDisposition) {
    return 'data';
  }

  const fileName = contentDisposition.split('filename=')[1];

  if (fileName) {
    return fileName.split('"').join('');
  }

  return 'data';
};

export const downloadResource = (response, filename) => {
  // eslint-disable-next-line no-undef
  const u = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = u;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const setSearchQueryParam = (query, queryType) => {
  if (!query) {
    return '';
  }
  if (queryType === 'all') {
    return `&search=${encodeURIComponent(query)}`;
  }
  if (queryType === 'devices__hardware_id') {
    return `&devices__hardware_id=${encodeURIComponent(query)}`;
  }
  return `&${queryType}__icontains=${encodeURIComponent(query)}`;
};

export const getLicenseAPIactionsOptions = () => {
  const actions = Object.keys(licenseAPIactions);
  const options = actions.map((i) => {
    const t = licenseAPIactions[i];
    return { ...t, value: i };
  });
  return options;
};

export const compareSimpleArrays = (array1, array2) => {
  const array2Sorted = array2.slice().sort();
  return array1.length === array2.length && array1.slice().sort().every((value, index) => value === array2Sorted[index]);
};

export const mapPermissionTemplatesToSelector = (templates) => {
  if (!templates || !Array.isArray(templates)) {
    return [];
  }
  return templates.map(t => ({
    ...t,
    value: t.id,
    label: t.name,
  }));
};

export const mapPlatformUserLabelsToSelector = (labels) => {
  if (!labels || !Array.isArray(labels)) {
    return [];
  }
  return labels.map(t => ({
    ...t,
    value: t.id,
    label: t.name,
  }));
};

export const getLink = (url) => {
  if (!url) { return ''; }
  const containProtocol = url.includes('http');
  if (!containProtocol) {
    return `//${url}`;
  }
  return url;
};

export const getAirgapPortalURL = () => {
  const env = process.env.REACT_APP_ENV;
  if (env === 'development' || env === 'localhost') { return 'https://dev.airgap.licensespring.com/'; }
  if (env === 'staging') { return 'https://stage.airgap.licensespring.com/'; }
  return 'https://airgap.licensespring.com/';
};

export const getOfflinePortalURL = () => {
  const env = process.env.REACT_APP_ENV;
  if (env === 'development' || env === 'localhost') { return 'https://dev.offline.licensespring.com/'; }
  if (env === 'staging') { return 'https://stage.offline.licensespring.com/'; }
  return 'https://offline.licensespring.com/';
};

export const getUserPortalURL = () => {
  const env = process.env.REACT_APP_ENV;
  if (env === 'development' || env === 'localhost') { return 'https://users-dev.licensespring.com/'; }
  if (env === 'staging') { return 'https://users-staging.licensespring.com/'; }
  return 'https://users.licensespring.com/';
};

export const formatOrderBy = (orderBy) => {
  if (!orderBy || !Array.isArray(orderBy) || !orderBy.length) {
    return '';
  }
  const values = `${orderBy.map(orderType => `${orderType.desc ? '-' : ''}${orderType.id},`)}`;
  const cleanList = values.split(',').filter(Boolean).join(',');
  return `&order_by=${cleanList}`;
};

export const maskString = (str, strLength = 5) => {
  if (!str) {
    return '';
  }
  if (str.length <= strLength) {
    return str; // Return the original string if it's already 5 characters or less
  }
  const maskedPart = '*'.repeat(str.length - strLength); // Create a string of asterisks with the same length as the remaining characters
  return str.substring(0, strLength) + maskedPart; // Concatenate the first 5 characters with the masked part
};
