import { useEffect } from 'react';
import { getAirgapPortalURL } from 'shared/helpers';

const AirGapPortal = () => {
  useEffect(() => {
    window.location.href = getAirgapPortalURL();
  }, []);

  return null;
};

export default AirGapPortal;
