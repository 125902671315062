import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  IconCheckmark,
  IconDisabled,
  List,
  Notification,
  PermissionMissingNotificationTitle,
} from 'shared/components';
import { platformFeatures, userPermissions } from 'shared/constants';
import {
  formatDate,
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  checkUserPermission,
} from 'shared/helpers';
import { getUsers } from 'src/account/actions';
import { AssignTemplateForm } from '../../Permissions/components';
import { AddUserForm } from '../components';

const UsersList = () => {
  const canManagePlatformUsers = checkUserPermission(userPermissions.platform_users_write);
  const history = useHistory();
  const dispatch = useDispatch();
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const loading = useSelector(state => get(state, 'users.loading'));
  const users = useSelector(state => get(state, 'users.list.results') || []);

  const [currentSort, setCurrentSort] = useState([]);
  const [showAddUserForm, setAddUserFormDisplay] = useState(false);
  const [showAssignTemplateForm, setAssignFormDisplay] = useState(false);

  useEffect(() => {
    dispatch(getUsers(companyID));
  }, [companyID]);

  const redirectToUserPage = (rowData) => {
    const userID = get(rowData, 'original.id');
    history.push(`/${companyID}/account/users/${userID}`);
  };

  const handleManagePlatformUsersClick = (cb) => {
    if (!canManagePlatformUsers) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.platform_users_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    cb(true);
    return true;
  };

  const handleAssignConfirm = () => {
    setAssignFormDisplay(false);
    dispatch(getUsers(companyID));
  };

  return (
    <div className="UsersList">
      <div className="list-header">
        <div>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_add_user)}
            featureAvailable={isFeatureAvailable(platformFeatures.platform_add_user)}
            notEnabledMessage={getDisabledMessage()}
            onClick={() => handleManagePlatformUsersClick(setAddUserFormDisplay)}
            theme="info"
            size="sm"
            disabled={loading}
          >
            {__('Add new User')}
          </Button>
        </div>
        <div>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_add_user)}
            featureAvailable={isFeatureAvailable(platformFeatures.platform_add_user)}
            notEnabledMessage={getDisabledMessage()}
            onClick={() => handleManagePlatformUsersClick(setAssignFormDisplay)}
            theme="default"
            size="sm"
            disabled={loading}
          >
            {__('Assign permissions template to user label')}
          </Button>
        </div>
      </div>
      <List
        clickable
        columns={[
          { accessor: 'email', Header: __('Email') },
          { accessor: 'first_name', Header: __('First name'), width: 150 },
          { accessor: 'last_name', Header: __('Last name'), width: 150 },
          {
            accessor: 'last_login',
            Header: __('Last login'),
            Cell: cellData => formatDate(get(cellData, 'value')),
            width: 150,
          },
          {
            accessor: 'email_verified',
            className: 'text-center',
            Header: __('Has accepted invitation'),
            headerClassName: 'text-center',
            Cell: cellData => (cellData.value ? <IconCheckmark color="#10ac84" height="14px" /> : <IconDisabled color="#aaa" height="14px" />),
            width: 180,
          },
          {
            accessor: 'is_active',
            className: 'text-center',
            Header: __('Active'),
            headerClassName: 'text-center',
            Cell: (cellData) => {
              const isActive = get(cellData, `original.is_active_on_company.${companyID}`);
              return isActive ? <IconCheckmark color="#10ac84" height="14px" /> : <IconDisabled color="red" height="14px" />;
            },
            width: 70,
          },
          {
            accessor: `is_admin.${companyID}`,
            className: 'text-center',
            Header: __('Admin'),
            headerClassName: 'text-center',
            Cell: cellData => (cellData.value ? <IconCheckmark color="#10ac84" height="14px" /> : <IconDisabled color="#aaa" height="14px" />),
            width: 70,
          },
          {
            accessor: 'roles',
            Header: __('Labels'),
            sortable: false,
            className: 'customer-label',
            Cell: (cellInfo) => {
              const labels = get(cellInfo, 'value') || [];
              const labelTitles = labels.map(l => l.name).sort().join(', ');
              return (
                <div className="customerLabels">
                  <span className="label-titles">{labelTitles}</span>
                  <div className="label-colors-wrapper">
                    {labels.map(l => (
                      <div className="label-color" key={l.id} style={{ backgroundColor: l.color || '#949494' }} />
                    ))}
                  </div>
                </div>
              );
            },
          },
        ]}
        data={users}
        defaultSorted={currentSort}
        loading={loading}
        handleClick={rowData => redirectToUserPage(rowData)}
        minRows={2}
        pageSize={20}
        onSortedChange={(newSorted) => {
          setCurrentSort(newSorted);
          dispatch(getUsers(companyID, newSorted));
        }}
      />
      {showAddUserForm && (
        <AddUserForm closeCb={() => setAddUserFormDisplay(false)} />
      )}
      {showAssignTemplateForm && (
        <AssignTemplateForm
          confirmCb={handleAssignConfirm}
          closeCb={() => setAssignFormDisplay(false)}
        />
      )}
    </div>
  );
};

export default UsersList;
