import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import TagManager from 'react-gtm-module';
import i18n from 'src/i18n';
import {
  ContentLoader,
  CustomPrompt,
  isAuthorized,
} from 'shared/components';
import { getCurrentUser } from 'src/account/actions';
import LoginContainer from './auth/LoginContainer';
import SSOContainer from './account/SSOContainer';
import AirGapPortal from './account/AirGapPortal';
import OfflinePortal from './account/OfflinePortal';
import AppContainer from './app/AppContainer';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

// test
/* eslint-disable-next-line new-cap, no-unused-vars */
const appLang = new i18n('en');

const App = () => {
  const dispatch = useDispatch();
  const userLoading = useSelector(state => get(state, 'account.userLoading'));
  const env = process.env.REACT_APP_ENV;

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  if (userLoading) {
    return <ContentLoader text="" />;
  }

  const getEnvTitle = () => {
    if (env === 'localhost') { return 'LicenseSpring Platform | LOCAL'; }
    if (env === 'development') { return 'LicenseSpring Platform | DEV'; }
    if (env === 'staging') { return 'LicenseSpring Platform | STAGING'; }
    return 'LicenseSpring Platform';
  };

  if (env !== 'localhost' && !!process.env.REACT_APP_GTM_ID) {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID,
      auth: process.env.REACT_APP_GTM_AUTH,
      preview: process.env.REACT_APP_GTM_PREVIEW,
    });
  }

  return (
    <div className="App">
      <Helmet>
        <title>{getEnvTitle()}</title>
      </Helmet>
      <Router
        getUserConfirmation={(message, callback) => (
          CustomPrompt(__(message), callback, __('Warning'))
        )}
      >
        <Switch>
          <Route exact path="/login" component={LoginContainer} />
          <Route exact path="/sso" component={SSOContainer} />
          <Route exact path="/air-gap" component={AirGapPortal} />
          <Route exact path="/offline" component={OfflinePortal} />
          <Route path="/" component={isAuthorized(AppContainer)} />
        </Switch>
      </Router>
      <ToastContainer
        autoClose={3000}
        closeButton={false}
        toastClassName="Notification-container"
      />
    </div>
  );
};

export default App;
