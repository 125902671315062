import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Redirect } from 'react-router-dom';
import { UnauthorizedNotice } from 'shared/components';

const mapStateToProps = state => ({
  companyId: get(state, 'company.details.id'),
  user: get(state, 'account.user'),
});

const isAuthorized = (WrappedComponent, role = 'user') => {
  const wrapped = ({ companyId, user }) => {
    if (!get(user, 'id')) {
      return <Redirect to="/login" />;
    }
    const isAdmin = get(user, `is_admin[${companyId}]`) || false;
    const isStaff = get(user, 'is_staff') || false;
    if (
      role === 'admin' &&
      !isStaff &&
      !isAdmin
    ) {
      return <UnauthorizedNotice />;
    }
    if (
      role === 'staff' &&
      !isStaff
    ) {
      return <UnauthorizedNotice />;
    }

    return <WrappedComponent />;
  };

  wrapped.propTypes = {
    companyId: PropTypes.number,
    user: PropTypes.object,
  };

  wrapped.defaultProps = {
    companyId: null,
    user: {},
  };

  return connect(mapStateToProps)(wrapped);
};

export default isAuthorized;
