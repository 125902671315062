import { useEffect } from 'react';
import { getOfflinePortalURL } from 'shared/helpers';

const AirGapPortal = () => {
  useEffect(() => {
    window.location.href = getOfflinePortalURL();
  }, []);

  return null;
};

export default AirGapPortal;
