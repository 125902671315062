/* eslint-disable no-prototype-builtins */
import {
  licenseTypes,
} from 'shared/constants';

// customerRequiredFields - email OR organization are required always, email is default

const companyConfigOverride = {
  // Olympus Australia
  24: {
    licenseTypes: [licenseTypes.perpetual, licenseTypes.time_limited],
    maxActivations: false,
    isTrial: false,
    isTest: false,
    maintenancePeriod: false,
    productFeatures: false,
    customerRequiredFields: ['organization', 'reference'],
    existingCustomerFilter: 'name',
  },
  // dev only TestEnt
  246: {
    licenseTypes: [licenseTypes.perpetual, licenseTypes.time_limited],
    maxActivations: false,
    isTrial: false,
    isTest: false,
    maintenancePeriod: false,
    productFeatures: false,
    customerRequiredFields: ['organization', 'reference'],
    existingCustomerFilter: 'name',
  },
  // staging only KrakenDemo
  7: {
    licenseTypes: [licenseTypes.perpetual, licenseTypes.time_limited],
    maxActivations: false,
    isTrial: false,
    isTest: false,
    maintenancePeriod: false,
    productFeatures: false,
    customerRequiredFields: ['organization', 'reference'],
    existingCustomerFilter: 'name',
  },
};

// returns boolean ( use only in conditional rendering )
export const checkCompanyConfigField = (companyId, fieldName) => {
  const hasConfig = companyConfigOverride.hasOwnProperty(companyId);
  if (!hasConfig) { return true; }

  const hasConfigField = companyConfigOverride[companyId].hasOwnProperty(fieldName);
  if (!hasConfigField) { return true; }

  return companyConfigOverride[companyId][fieldName];
};

// returns field or fallback
export const getCompanyConfigFieldValue = (companyId, fieldName, fallback = undefined) => {
  const hasConfig = companyConfigOverride.hasOwnProperty(companyId);
  if (!hasConfig) { return fallback; }

  const hasConfigField = companyConfigOverride[companyId].hasOwnProperty(fieldName);
  if (!hasConfigField) { return fallback; }

  return companyConfigOverride[companyId][fieldName];
};
