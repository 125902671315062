import React, { useState } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';
import {
  formatEventsToSelector,
  formatEmailVariablesToSelector,
  sendErrorReport,
  checkUserPermission,
} from 'shared/helpers';
import { userPermissions } from 'shared/constants';
import {
  Button,
  CheckboxSelector,
  ColorPicker,
  Label,
  Notice,
  Selector,
  TextInput,
  TextArea,
  Notification,
  PermissionMissingNotificationTitle,
} from 'shared/components';
import {
  updateNotificationPolicy,
  getNotificationPolicies,
  resetNotificationPolicy,
} from 'src/notifications/actions';
import EmailPreview from '../EmailPreview';
import './styles.scss';

const mapEventsToSelector = (data) => {
  const events = data.map(np => np.event);
  return formatEventsToSelector(events);
};

const getActiveVariables = vars => vars.filter(v => get(v, 'data.show'));

const mapVariables = (list, selected) => {
  const result = list.map((i) => {
    const isSelected = selected.find(s => get(s, 'data.code') === get(i, 'data.code'));
    if (isSelected) {
      return ({
        ...i.data,
        show: true,
      });
    }

    return ({
      ...i.data,
      show: false,
    });
  });

  return result;
};

const EmailConfiguration = () => {
  const canManageSettings = checkUserPermission(userPermissions.settings_write);
  const dispatch = useDispatch();
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const emailSender = get(companyDetails, 'email_sender');
  const companyID = get(companyDetails, 'id');
  const planType = get(companyDetails, 'plan_type');
  const isEnterprise = planType === 'enterprise';
  const notificationPolicies = useSelector(state => get(state, 'notifications.notification_policies') || []);
  const eventsOptions = mapEventsToSelector(notificationPolicies);
  const initialEvent = get(eventsOptions, '[0]');
  const initialPolicy = get(notificationPolicies, '[0]');
  const initialEmailOptions = get(notificationPolicies, '[0].email_options');

  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(initialEvent);
  const [selectedPolicy, setSelectedPolicy] = useState(initialPolicy);
  const [subject, setSubject] = useState(get(initialEmailOptions, 'subject'));
  const [title, setTitle] = useState(get(initialEmailOptions, 'title'));
  const [text, setText] = useState(get(initialEmailOptions, 'text'));
  const [accentColor, setAccentColor] = useState(get(initialEmailOptions, 'color') || '');
  const [variables, setVariables] = useState(formatEmailVariablesToSelector(get(initialPolicy, 'email_options.variables')));
  const [selectedVariables, setSelectedVariables] = useState(getActiveVariables(formatEmailVariablesToSelector(get(initialPolicy, 'email_options.variables'))));
  const [userPortalVariables, setUserPortalVariables] = useState(formatEmailVariablesToSelector(get(initialPolicy, 'email_options.user_portal_variables')));
  const [selectedUserPortalVariables, setSelectedUserPortalVariables] = useState(getActiveVariables(formatEmailVariablesToSelector(get(initialPolicy, 'email_options.user_portal_variables'))));
  const [licensesVariables, setLicensesVariables] = useState(formatEmailVariablesToSelector(get(initialPolicy, 'email_options.license_list_variables')));
  const [selectedLicensesVariables, setSelectedLicensesVariables] = useState(getActiveVariables(formatEmailVariablesToSelector(get(initialPolicy, 'email_options.license_list_variables'))));

  const handleEventChange = (eventID) => {
    const selected = eventsOptions.find(option => option.value === eventID);
    const policy = notificationPolicies.find(np => np.event.id === eventID);
    setSelectedEvent(selected);
    setSelectedPolicy(policy);
    setSubject(get(policy, 'email_options.subject'));
    setTitle(get(policy, 'email_options.title'));
    setText(get(policy, 'email_options.text'));
    setAccentColor(get(policy, 'email_options.color'));
    setVariables(formatEmailVariablesToSelector(get(policy, 'email_options.variables')));
    setSelectedVariables(getActiveVariables(formatEmailVariablesToSelector(get(policy, 'email_options.variables'))));
    setUserPortalVariables(formatEmailVariablesToSelector(get(policy, 'email_options.user_portal_variables')));
    setSelectedUserPortalVariables(getActiveVariables(formatEmailVariablesToSelector(get(policy, 'email_options.user_portal_variables'))));
    setLicensesVariables(formatEmailVariablesToSelector(get(policy, 'email_options.license_list_variables')));
    setSelectedLicensesVariables(getActiveVariables(formatEmailVariablesToSelector(get(policy, 'email_options.license_list_variables'))));
  };

  const resetValues = (policy) => {
    setSelectedPolicy(policy);
    setSubject(get(policy, 'email_options.subject'));
    setTitle(get(policy, 'email_options.title'));
    setText(get(policy, 'email_options.text'));
    setAccentColor(get(policy, 'email_options.color'));
    setVariables(formatEmailVariablesToSelector(get(policy, 'email_options.variables')));
    setSelectedVariables(getActiveVariables(formatEmailVariablesToSelector(get(policy, 'email_options.variables'))));
    setUserPortalVariables(formatEmailVariablesToSelector(get(policy, 'email_options.user_portal_variables')));
    setSelectedUserPortalVariables(getActiveVariables(formatEmailVariablesToSelector(get(policy, 'email_options.user_portal_variables'))));
    setLicensesVariables(formatEmailVariablesToSelector(get(policy, 'email_options.license_list_variables')));
    setSelectedLicensesVariables(getActiveVariables(formatEmailVariablesToSelector(get(policy, 'email_options.license_list_variables'))));
  };

  const handleSubmit = () => {
    if (isLoading || !isDirty) {
      return false;
    }
    const policyID = get(selectedPolicy, 'id');
    const data = {
      email_options: {
        subject,
        title,
        text,
        color: accentColor,
        variables: mapVariables(variables, selectedVariables),
        user_portal_variables: mapVariables(userPortalVariables, selectedUserPortalVariables),
        license_list_variables: mapVariables(licensesVariables, selectedLicensesVariables),
      },
    };
    setLoading(true);

    updateNotificationPolicy(policyID, companyID, data)
      .then(() => {
        setLoading(false);
        setDirty(false);
        dispatch(getNotificationPolicies(companyID));
        Notification('success', __('Changes saved successfully'));
      })
      .catch((err) => {
        setLoading(false);
        setDirty(false);
        sendErrorReport(err, 'Cannot update notification policy email options', data);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
    return true;
  };

  const handlePolicyReset = () => {
    if (isLoading) {
      return false;
    }
    const policyID = get(selectedPolicy, 'id');
    setLoading(true);
    resetNotificationPolicy(policyID, companyID)
      .then((res) => {
        resetValues(get(res, 'data'));
        setLoading(false);
        setDirty(false);
        dispatch(getNotificationPolicies(companyID));
        Notification('success', __('Changes saved successfully'));
      })
      .catch((err) => {
        setLoading(false);
        setDirty(false);
        sendErrorReport(err, 'Cannot reset notification policy email options');
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
    return true;
  };

  const handleManageNotificationsClick = (cb) => {
    if (!canManageSettings) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.settings_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    cb(true);
    return true;
  };

  return (
    <div className="EmailConfiguration">
      <Prompt
        when={isDirty}
        message={__('You have unsaved changes. Are you sure you want to leave?')}
      />
      {!isEnterprise && (
        <Notice size="sm" title={__('Customizing email templates is not available in your plan type.')} theme="warning">
          <div>{__('Upgrade your plan to use this feature.')}</div>
        </Notice>
      )}
      {emailSender && (
        <div className="EmailConfiguration-sender">
          <Label text={__('Email address')} inputId="email-sender" />
          <div className="email">{emailSender}</div>
        </div>
      )}
      <div className="Wrapper">
        <div className="Configurator">
          <div className="form-row event-selector">
            <Label inputId="events-selector" text={__('Event')} />
            <Selector
              options={eventsOptions}
              value={selectedEvent.value}
              handleChange={handleEventChange}
              disabled={isLoading}
            />
          </div>
          <div className="form-row">
            <Label inputId="subject-input" text={__('Subject')} />
            <TextInput
              disabled={isLoading || !isEnterprise}
              id="subject-input"
              type="text"
              value={subject}
              handleChange={(val) => {
                setDirty(true);
                setSubject(val);
              }}
            />
          </div>
          <div className="form-row">
            <Label inputId="title-input" text={__('Title')} />
            <TextInput
              disabled={isLoading || !isEnterprise}
              id="title-input"
              type="text"
              value={title}
              handleChange={(val) => {
                setDirty(true);
                setTitle(val);
              }}
            />
          </div>
          <div className="form-row">
            <Label inputId="text-input" text={__('Text')} />
            <TextArea
              id="text-input"
              disabled={isLoading || !isEnterprise}
              value={text}
              handleChange={(val) => {
                setDirty(true);
                setText(val);
              }}
            />
          </div>
          {accentColor ? (
            <div className="form-row">
              <Label text={__('Accent color')} inputId="new-label-color" />
              <ColorPicker
                initialColor={accentColor}
                onColorSelect={(val) => {
                  setDirty(true);
                  setAccentColor(val);
                }}
                label={accentColor}
                disabled={isLoading || !isEnterprise}
              />
            </div>
          ) : null}
          {variables.length ? (
            <div className="form-row checkbox">
              <CheckboxSelector
                text={__('Select fields to show')}
                options={variables}
                disabled={isLoading || !isEnterprise}
                value={selectedVariables}
                onChangeCallback={(val) => {
                  setDirty(true);
                  setSelectedVariables(val);
                }}
                onMenuClose={() => { }}
              />
            </div>
          ) : null}
          {userPortalVariables.length ? (
            <div className="form-row checkbox">
              <CheckboxSelector
                text={__('Select user portal fields')}
                options={userPortalVariables}
                value={selectedUserPortalVariables}
                onChangeCallback={(val) => {
                  setDirty(true);
                  setSelectedUserPortalVariables(val);
                }}
                onMenuClose={() => { }}
                disabled={isLoading || !isEnterprise}
              />
            </div>
          ) : null}
          {licensesVariables.length ? (
            <div className="form-row checkbox">
              <CheckboxSelector
                text={__('Select licenses list fields')}
                options={licensesVariables}
                value={selectedLicensesVariables}
                onChangeCallback={(val) => {
                  setDirty(true);
                  setSelectedLicensesVariables(val);
                }}
                onMenuClose={() => { }}
                disabled={isLoading || !isEnterprise}
              />
            </div>
          ) : null}
          <div className="buttons-container">
            <Button
              theme="success"
              disabled={isLoading || !isEnterprise}
              onClick={() => handleManageNotificationsClick(handleSubmit)}
            >
              {__('Save')}
            </Button>
            <Button
              theme="info"
              disabled={isLoading || !isEnterprise}
              onClick={() => handleManageNotificationsClick(handlePolicyReset)}
            >
              {__('Reset to default')}
            </Button>
          </div>
        </div>
        <EmailPreview
          policy={selectedPolicy}
          subject={subject}
          title={title}
          text={text}
          accentColor={accentColor}
          variables={selectedVariables}
          userPortalVariables={selectedUserPortalVariables}
          licensesVariables={selectedLicensesVariables}
        />
      </div>
    </div>
  );
};

export default EmailConfiguration;
