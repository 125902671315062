import * as companyActionTypes from 'src/company/actionTypes';
import { removeForbiddenCompanies } from 'shared/helpers';
import * as actionTypes from './actionTypes';

const checkInitialDisableStaff = () => {
  const config = window.sessionStorage.getItem('lcs-debug-staff');

  if (!config || config === 'false') {
    return false;
  }

  return true;
};

const handleDisableStaff = (isDisabled) => {
  window.sessionStorage.setItem('lcs-debug-staff', isDisabled);
  return isDisabled;
};

const checkInitialSidebarSize = () => {
  const config = window.localStorage.getItem('lcs-sidebar-width');
  if (!config) { return 'lg'; }
  return config;
};

const handleSidebarSize = (sidebarWidth) => {
  window.localStorage.setItem('lcs-sidebar-width', sidebarWidth);
  return sidebarWidth;
};

const initialState = {
  userLoading: true,
  checkingUser: false,
  companiesLoading: true,
  loading: false,
  disableStaff: checkInitialDisableStaff(),
  sidebarSize: checkInitialSidebarSize(),
  invoices: [],
  invoicesLoading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.getCurrentUser:
      return Object.assign({}, state, {
        userLoading: true,
      });
    case actionTypes.getCurrentUserSuccess:
      return Object.assign({}, state, {
        userLoading: false,
        user: action.user,
      });
    case actionTypes.getCurrentUserError:
      return Object.assign({}, state, {
        userLoading: false,
      });
    case actionTypes.loginInit:
      return Object.assign({}, state, {
        checkingUser: true,
      });
    case actionTypes.loginSuccess:
      return Object.assign({}, state, {
        user: action.user,
        checkingUser: false,
      });
    case actionTypes.loginError:
      return Object.assign({}, state, {
        error: action.payload,
        checkingUser: false,
      });
    case actionTypes.changeActiveCompany:
      return Object.assign({}, state, {
        activeCompany: action.company,
      });
    case actionTypes.logoutSuccess: {
      return Object.assign({}, initialState, {
        userLoading: false,
      });
    }
    case actionTypes.logoutError: {
      return Object.assign({}, state, {
        error: action.payload,
      });
    }
    case actionTypes.refreshCurrentUser: {
      return Object.assign({}, state, {
        user: action.user,
      });
    }
    case actionTypes.getUserCompaniesInit: {
      return Object.assign({}, state, {
        companiesLoading: true,
      });
    }
    case actionTypes.getUserCompaniesSuccess: {
      return Object.assign({}, state, {
        activeCompany: action.activeCompany,
        companies: removeForbiddenCompanies(state.user, action.companies),
        companiesLoading: false,
      });
    }
    case actionTypes.getUserCompaniesError: {
      return Object.assign({}, state, {
        companiesLoading: false,
      });
    }
    case actionTypes.disableStaff: {
      return Object.assign({}, state, {
        disableStaff: handleDisableStaff(action.disableStaff),
      });
    }
    case actionTypes.changeSidebarSize: {
      return Object.assign({}, state, {
        sidebarSize: handleSidebarSize(action.sidebarSize),
      });
    }
    case companyActionTypes.getCompanyInit: {
      return Object.assign({}, state, {
        activeCompany: {
          id: action.company,
        },
      });
    }
    case companyActionTypes.clearActiveCompany: {
      return Object.assign({}, state, {
        activeCompany: undefined,
      });
    }
    case actionTypes.getInvoicesInit:
      return Object.assign({}, state, {
        invoicesLoading: true,
      });
    case actionTypes.getInvoicesSuccess:
      return Object.assign({}, state, {
        invoicesLoading: false,
        invoices: action.payload,
      });
    case actionTypes.getInvoicesError:
      return Object.assign({}, state, {
        invoicesLoading: false,
      });
    default:
      return Object.assign(state);
  }
}
