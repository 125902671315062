import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import Raven from 'raven-js';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';
import './index.css';

if (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'staging') {
  Raven.config('https://e73fe4d0db3d44d5891242fea0e0822b@sentry.kraken.hr/11', {
    environment: process.env,
    version: 'v2',
  }).install();
}

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'), // eslint-disable-line no-undef
);
