import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { sendErrorReport } from 'shared/helpers';
import {
  Button,
  ConfirmationPopup,
  Notice,
  Notification,
  Page,
  Subtitle,
} from 'shared/components';
import { refreshCurrentUser } from 'src/account/actions';
import { disable2FA } from 'src/user/actions';
import { ReactComponent as TwofaImage } from './twofa-image.svg';
import TwofaSetupForm from './TwofaSetupForm';
import './styles.scss';

const SecurityContainer = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => get(state, 'account.user'));

  const [showTwofaForm, setTwofaFormDisplay] = useState(false);
  const [isDisableTwofaWarningDisplayed, setIsDisableTwofaWarningDisplayed] = useState(false);
  const [disableTwofaLoading, setDisableTwofaLoading] = useState(false);

  const handleTwofaDisableSubmit = () => {
    const userId = get(user, 'id');
    setDisableTwofaLoading(true);

    disable2FA(userId)
      .then((res) => {
        dispatch(refreshCurrentUser(res.data));
        setDisableTwofaLoading(false);
        setIsDisableTwofaWarningDisplayed(false);
        Notification('error', __('Two-factor authentication disabled succesfully'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot disable 2FA');
        setDisableTwofaLoading(false);
        Notification('error', __('Error occured'), __('There was an error while saving your changes'));
      });
  };

  const handleTwofaDisableBtnClick = () => {
    const isGlobal2fa = get(user, 'twofa_company_required');
    if (isGlobal2fa) {
      Notification('error', __('Two-factor authentication cannot be disabled'), __('2FA authentification is required for this company'));
      return false;
    }
    setIsDisableTwofaWarningDisplayed(true);
    return true;
  };

  const is2FAEnabled = get(user, 'twofa_required');

  return (
    <Page title={__('Security')}>
      <div className="SecurityContainer">
        <div className="SecurityContainer-twofa">
          <div className="section-header">
            <Subtitle text={__('Two-factor authentication')} />
          </div>
          <div className="section-body">
            <div className="description">
              {__('Two factor authentication adds an extra layer of security to your account by asking for a verification code when you sign in.')}
              <Notice
                theme={is2FAEnabled ? 'success' : 'error'}
                size="sm"
              >
                {is2FAEnabled ? __('Two-factor authentication is enabled') : __('Two-factor authentication is not enabled')}
              </Notice>
            </div>
            <div className="image-container"><TwofaImage /></div>
            <div>
              <ul>
                <li>{__('Log in using your email and password as usual.')}</li>
                <li>{__('Using a two-factor authentication app, generate a passcode to verify your identity.')}</li>
                <li>{__('Once you enter the passcode at the prompt, you will be logged in to the platform.')}</li>
              </ul>
            </div>
            {!is2FAEnabled ? (
              <Button
                theme="success"
                onClick={() => setTwofaFormDisplay(true)}
              >
                {__('Setup 2FA authentication')}
              </Button>
            ) : (
              <Button
                theme="default"
                onClick={handleTwofaDisableBtnClick}
              >
                {__('Disable 2FA authentication')}
              </Button>
            )}
          </div>
        </div>
      </div>
      {showTwofaForm && (
        <TwofaSetupForm closeCb={() => setTwofaFormDisplay(false)} />
      )}
      {isDisableTwofaWarningDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setDisableTwofaLoading(false);
            setIsDisableTwofaWarningDisplayed(false);
          }}
          confirmCb={() => handleTwofaDisableSubmit()}
          confirmText={__('Confirm')}
          theme="error"
          warning
          disabled={disableTwofaLoading}
        >
          {__('Please, confirm that you really want to disable the two-factor authentication.')}
        </ConfirmationPopup>
      )}
    </Page>
  );
};

export default SecurityContainer;
