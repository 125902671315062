import React, { useState, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';
import {
  Button,
  Checkbox,
  DescriptionTable,
  ConfirmationPopup,
  IconDelete,
  List,
  Notice,
  Notification,
  PermissionMissingNotificationTitle,
} from 'shared/components';
import {
  sendErrorReport,
  displayValue,
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  checkUserPermission,
} from 'shared/helpers';
import { platformFeatures, userPermissions, ssoProvidersList } from 'shared/constants';
import { updateCompanyData } from 'src/company/actions';
import {
  updateSSOSettings,
  getSSOProviders,
  deleteSSOProvider,
  getUserPoolVariables,
} from 'src/account/actions';
import AddSSOProviderForm from '../AddSSOProviderForm';
import './styles.scss';

const SsoSettings = () => {
  const canManageSettings = checkUserPermission(userPermissions.settings_write);
  const dispatch = useDispatch();

  const companyDetails = useSelector(state => get(state, 'company.details'));
  const companyID = get(companyDetails, 'id');
  const ssoEnabled = get(companyDetails, 'cognito_enabled');
  const ssoCreateUser = get(companyDetails, 'cognito_new_user_create');

  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isEnabled, setEnabled] = useState(ssoEnabled);
  const [createUser, setCreateUser] = useState(ssoCreateUser);
  const [providersLoading, setProvidersLoading] = useState(true);
  const [providers, setProviders] = useState([]);
  const [providerToDelete, setProviderToDelete] = useState(null);
  const [isProviderDeleteConfirmationDisplayed, setProviderDeleteConfirmationDisplayed] = useState(false);
  const [providerDeleteLoading, setProviderDeleteLoading] = useState(false);
  const [isFormDisplayed, setFormDisplay] = useState(false);
  const [userPoolVariablesLoading, setUserPoolVariablesLoading] = useState(true);
  const [userPoolVariables, setUserPoolVariables] = useState(null);

  const getProviders = useCallback(() => {
    getSSOProviders(companyID)
      .then((res) => {
        const data = get(res, 'data') || [];
        setProviders(data);
        setProvidersLoading(false);
      })
      .catch(() => {
        setProvidersLoading(false);
      });
  }, [companyID]);

  const getUserPool = useCallback(() => {
    getUserPoolVariables(companyID)
      .then((res) => {
        const data = get(res, 'data') || {};
        setUserPoolVariables(data);
        setUserPoolVariablesLoading(false);
      })
      .catch(() => {
        setUserPoolVariablesLoading(false);
      });
  }, [companyID]);

  useEffect(() => {
    getProviders();
  }, [getProviders]);

  useEffect(() => {
    getUserPool();
  }, [getUserPool]);

  const submitSSOChange = () => {
    setLoading(true);
    const data = {
      enabled: isEnabled,
      new_user_create: createUser,
    };

    updateSSOSettings(companyID, data)
      .then((res) => {
        const newData = get(res, 'data');
        Notification('success', __('Changes saved successfully'));
        setLoading(false);
        setDirty(false);
        dispatch(updateCompanyData(newData));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot edit company sso data', data);
        setLoading(false);
        setDirty(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const handleProviderDelete = () => {
    setProviderDeleteLoading(true);
    const data = { provider_name: get(providerToDelete, 'provider_name') };
    deleteSSOProvider(companyID, data)
      .then(() => {
        Notification('success', __('Changes saved successfully'));
        setProviderDeleteLoading(false);
        setProviderToDelete(null);
        setProviderDeleteConfirmationDisplayed(false);
        getProviders();
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot delete company sso provider', data);
        setProviderDeleteLoading(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const handleManageSettingsClick = (cb) => {
    if (!canManageSettings) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.settings_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    cb(true);
    return true;
  };

  const getProviderLabel = (provider) => {
    const providerOption = ssoProvidersList.find(p => p.value === provider);
    if (providerOption) {
      return providerOption.label;
    }
    return provider;
  };

  return (
    <div className="SsoSettings">
      <Prompt
        when={isDirty}
        message={__('You have unsaved changes. Are you sure you want to leave?')}
      />
      <div className="SsoSettings-description">
        <Notice
          theme={ssoEnabled ? 'success' : 'error'}
          size="sm"
        >
          {ssoEnabled ? __('Single Sign On authentication is enabled') : __('Single Sign On authentication is not enabled')}
        </Notice>
      </div>
      <DescriptionTable
        details={[
          { label: __('user_pool_id'), value: displayValue(get(userPoolVariables, 'id')) },
          { label: __('domain_name'), value: displayValue(get(userPoolVariables, 'domain_name')) },
          { label: __('name'), value: displayValue(get(userPoolVariables, 'name')) },
          { label: __('region'), value: displayValue(get(userPoolVariables, 'region')) },
        ]}
        loading={userPoolVariablesLoading}
      />
      <div className="SsoSettings-form">
        <div className="row">
          <Checkbox
            label={__('Enable SSO')}
            inputId="sso-enabled"
            checked={isEnabled}
            handleChange={(val) => {
              setDirty(true);
              setEnabled(val);
            }}
          />
        </div>
        <div className="row">
          <Checkbox
            label={__('Create new platform user if it does not exist already')}
            inputId="sso-create-user"
            checked={createUser}
            handleChange={(val) => {
              setDirty(true);
              setCreateUser(val);
            }}
          />
        </div>
        <div className="row btn-row">
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.extra_single_sign_on)}
            featureAvailable={isFeatureAvailable(platformFeatures.extra_single_sign_on)}
            notEnabledMessage={getDisabledMessage()}
            theme="success"
            onClick={() => handleManageSettingsClick(submitSSOChange)}
            disabled={isLoading}
          >
            {__('Save changes')}
          </Button>
        </div>
      </div>
      <div className="row table-row">
        <h3>{__('SSO Providers')}</h3>
        <Button
          featureEnabled={isFeatureEnabled(platformFeatures.extra_single_sign_on)}
          featureAvailable={isFeatureAvailable(platformFeatures.extra_single_sign_on)}
          notEnabledMessage={getDisabledMessage()}
          size="sm"
          theme="info"
          loading={providersLoading}
          disabled={providersLoading || isLoading || !ssoEnabled}
          onClick={() => handleManageSettingsClick(setFormDisplay)}
        >
          {__('Add new provider')}
        </Button>
        <div className="providers-list">
          <List
            columns={[
              {
                accessor: 'provider_name',
                Header: __('Name'),
                Cell: cellData => displayValue(get(cellData, 'value')),
              },
              {
                accessor: 'provider_type',
                Header: __('Type'),
                Cell: cellData => getProviderLabel(get(cellData, 'value')),
              },
              {
                Header: __('Delete'),
                headerClassName: 'text-center',
                className: 'text-center',
                id: 'delete',
                width: 80,
                sortable: false,
                Cell: rowData => (
                  <Button
                    className="edit-button"
                    onClick={() => handleManageSettingsClick(() => {
                      setProviderToDelete(rowData.original);
                      setProviderDeleteConfirmationDisplayed(true);
                    })}
                    type="button"
                  >
                    <IconDelete height="16px" width="16px" color="#ee5253" />
                  </Button>
                ),
              },
            ]}
            data={providers}
            minRows={2}
            showPagination={false}
            loading={providersLoading}
            clickable={false}
          />
        </div>
      </div>
      {isProviderDeleteConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setProviderToDelete(null);
            setProviderDeleteConfirmationDisplayed(false);
          }}
          confirmCb={handleProviderDelete}
          title={`${__('Are you sure you want to delete this SSO provider')}?`}
          confirmText={__('Delete')}
          theme="error"
          disabled={providerDeleteLoading}
        >
          <span style={{ fontSize: '14px', wordBreak: 'break-all' }}>{get(providerToDelete, 'provider_name')}</span>
        </ConfirmationPopup>
      )}
      {isFormDisplayed && (
        <AddSSOProviderForm
          companyID={companyID}
          refetchProviders={getProviders}
          providers={providers}
          closeCb={() => setFormDisplay(false)}
        />
      )}
    </div>
  );
};

export default SsoSettings;
