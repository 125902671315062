import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  DescriptionTable,
  Page,
} from 'shared/components';
import {
  displayValue,
} from 'shared/helpers';

const Welcome = () => {
  const user = useSelector(state => get(state, 'account.user'));
  return (
    <Page title={__('Profile')}>
      <div className="Welcome">
        <DescriptionTable
          details={[
            { label: __('Email'), value: displayValue(get(user, 'email')) },
            { label: __('First Name'), value: displayValue(get(user, 'first_name')) },
            { label: __('Last Name'), value: displayValue(get(user, 'last_name')) },
            { label: __('Phone number'), value: displayValue(get(user, 'phone_number')) },
          ]}
        />
      </div>
    </Page>
  );
};

export default Welcome;
