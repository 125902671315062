export const getCompanyInit = 'GET_COMPANY_INIT';
export const getCompanySuccess = 'GET_COMPANY_SUCCESS';
export const getCompanyError = 'GET_COMPANY_ERROR';
export const clearActiveCompany = 'CLEAR_ACTIVE_COMPANY';

// company features
export const getCompanyFeaturesSuccess = 'GET_COMPANY_FEATURES_SUCCESS';
export const getCompanyFeaturesError = 'GET_COMPANY_FEATURES_ERROR';

export const getUserPreferencesInit = 'GET_USER_PREFERENCES_INIT';
export const getUserPreferencesError = 'GET_USER_PREFERENCES_ERROR';
export const getUserPreferencesSuccess = 'GET_USER_PREFERENCES_SUCCESS';

export const getManagerPermissionsInit = 'GET_MANAGER_PERMISSIONS_INIT';
export const getManagerPermissionsError = 'GET_MANAGER_PERMISSIONS_ERROR';
export const getManagerPermissionsSuccess = 'GET_MANAGER_PERMISSIONS_SUCCESS';

// customer labels
export const getCustomerLabelsInit = 'GET_CUSTOMER_LABELS_INIT';
export const getCustomerLabelsSuccess = 'GET_CUSTOMER_LABELS_SUCCESS';
export const getCustomerLabelsError = 'GET_CUSTOMER_LABELS_ERROR';
export const addNewCustomerLabel = 'ADD_NEW_CUSTOMER_LABEL';
export const removeLabel = 'REMOVE_LABEL';
export const updateLabel = 'UPDATE_LABEL';

// user labels
export const getUserLabelsInit = 'GET_USER_LABELS_INIT';
export const getUserLabelsSuccess = 'GET_USER_LABELS_SUCCESS';
export const getUserLabelsError = 'GET_USER_LABELS_ERROR';
export const addNewUserLabel = 'ADD_NEW_USER_LABEL';
export const removeUserLabel = 'REMOVE_USER_LABEL';
export const updateUserLabel = 'UPDATE_USER_LABEL';

// permissions
export const getPermissionsInit = 'GET_PERMISSIONS_INIT';
export const getPermissionsSuccess = 'GET_PERMISSIONS_SUCCESS';
export const getPermissionsError = 'GET_PERMISSIONS_ERROR';

// user permissions
export const getUserPermissionsInit = 'GET_USER_PERMISSIONS_INIT';
export const getUserPermissionsSuccess = 'GET_USER_PERMISSIONS_SUCCESS';
export const getUserPermissionsError = 'GET_USER_PERMISSIONS_ERROR';

// user permissions
export const getUserPermissionsTemplatesInit = 'GET_USER_PERMISSIONS_TEMPLATES_INIT';
export const getUserPermissionsTemplatesSuccess = 'GET_USER_PERMISSIONS_TEMPLATES_SUCCESS';
export const getUserPermissionsTemplatesError = 'GET_USER_PERMISSIONS_TEMPLATES_ERROR';

// integrations
export const getAccountIntegrationsInit = 'GET_ACCOUNT_INTEGRATIONS_INIT';
export const getAccountIntegrationsSuccess = 'GET_ACCOUNT_INTEGRATIONS_SUCCESS';
export const getAccountIntegrationsError = 'GET_ACCOUNT_INTEGRATIONS_ERROR';

// ui options actions
export const updateUserPreferences = 'UPDATE_USER_PREFERENCES';

// Refresh data
export const refreshCardData = 'REFRESH_CARD_DATA';
export const refreshCompanyData = 'REFRESH_COMPANY_DATA';

// Refresh trial days
export const refreshTrialDays = 'REFRESH_TRIAL_DAYS';

// portal data
export const updatePortalData = 'UPDATE_PORTAL_DATA';
