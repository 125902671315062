import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  checkTrial,
} from 'shared/helpers';
import InactiveAlert from './InactiveAlert';
import NoPaymentMethod from './NoPaymentMethod';
import TrialStandardNotice from './TrialStandardNotice';
import TrialNotice from './TrialNotice';
import LinkedCompanyNotice from './LinkedCompanyNotice';
import './styles.scss';

// const isCompanyInactive = (company) => {
//   if (get(company, 'inactive_from')) {
//     return true;
//   }
//   return false;
// };

const isTrialStandard = (company) => {
  const paymentMethod = get(company, 'payment_card_id') || undefined;
  const trial = checkTrial();
  return trial.isTrial && !paymentMethod;
};

const isTrial = () => {
  const trial = checkTrial();
  return trial.isTrial;
};

const hasPaymentMethod = (company) => {
  const isManualPayment = get(company, 'is_payment_manual');
  const paymentMethod = get(company, 'payment_card_id') || undefined;
  if (isManualPayment) {
    return true;
  }
  return paymentMethod;
};

const displayNotice = (company, theme, isAdmin, companies = [], invoices = []) => {
  const planType = get(company, 'plan_type');
  const isLinkedAccount = !!get(company, 'main');
  const isPaymentManual = get(company, 'is_payment_manual');
  const isDEVCompany = get(company, 'trial_start') === null;
  const isMainAccAvailable = !!companies.find(c => c.id === get(company, 'main'));
  const hasUnpaidInvoices = invoices.some(i => i.status === 'open' || i.status === 'uncollectible');

  if (!company) {
    return null;
  }
  if (planType === 'free' && !isLinkedAccount) {
    return null;
  }
  if (isPaymentManual || isDEVCompany) {
    return null;
  }
  if (hasUnpaidInvoices) {
    return <InactiveAlert company={get(company, 'id')} theme={theme} isAdmin={isAdmin} />;
  }
  if (isTrialStandard(company)) {
    return <TrialStandardNotice company={get(company, 'id')} theme={theme} trial={checkTrial()} isAdmin={isAdmin} />;
  }
  if (isTrial()) {
    return <TrialNotice theme="warning" trial={checkTrial()} />;
  }
  if (isLinkedAccount) {
    return <LinkedCompanyNotice theme="success" company={company} isMainAccAvailable={isMainAccAvailable} />;
  }
  if (!hasPaymentMethod(company)) {
    return <NoPaymentMethod company={get(company, 'id')} companyDetails={company} theme={theme} isAdmin={isAdmin} />;
  }

  return null;
};

const PageNotice = ({
  accountCompanies,
  company,
  isStaff,
  theme,
  userRoles,
  invoices,
}) => {
  const companyId = get(company, 'id');
  const isAdmin = get(userRoles, `[${companyId}]`) || isStaff;

  return (
    <>
      {displayNotice(company, theme, isAdmin, accountCompanies, invoices)}
    </>
  );
};

PageNotice.propTypes = {
  accountCompanies: PropTypes.array.isRequired,
  invoices: PropTypes.array.isRequired,
  company: PropTypes.object,
  isStaff: PropTypes.bool.isRequired,
  theme: PropTypes.oneOf(['success', 'warning', 'info', 'error']),
  userRoles: PropTypes.object,
};

PageNotice.defaultProps = {
  company: null,
  theme: 'error',
  userRoles: {},
};

const mapStateToProps = state => ({
  company: get(state, 'company.details'),
  userRoles: get(state, 'account.user.is_admin'),
  isStaff: get(state, 'account.user.is_staff'),
  accountCompanies: get(state, 'account.companies'),
  invoices: get(state, 'account.invoices'),
});

export default connect(mapStateToProps)(PageNotice);
