import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

const AuthorizedElement = ({
  children,
  level,
}) => {
  const account = useSelector(state => get(state, 'account'));
  const user = useSelector(state => get(state, 'account.user'));
  const companyId = useSelector(state => get(state, 'company.details.id'));

  const isAdmin = get(user, `is_admin[${companyId}]`) || false;
  const isStaff = get(user, 'is_staff') || false;
  const isStaffDisabled = get(account, 'disableStaff');

  if (
    level === 'admin' &&
    !isStaff &&
    !isAdmin
  ) {
    return null;
  }
  if (
    level === 'staff' && (!isStaff || isStaffDisabled)
  ) {
    return null;
  }

  return children;
};

AuthorizedElement.propTypes = {
  children: PropTypes.element.isRequired,
  level: PropTypes.string,
};

AuthorizedElement.defaultProps = {
  level: 'user',
};

export default AuthorizedElement;
