import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import {
  Page,
  Notice,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
} from 'shared/components';
import Emails from '../Emails';
import NotificationHistory from '../NotificationHistory';
import NotificationPolicies from '../NotificationPolicies';

const NotificationsContainer = () => {
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const planType = get(companyDetails, 'plan_type');

  if (planType === 'free') {
    return (
      <Page title={__('Notifications')}>
        <Notice size="sm" title={__('Notifications are not available in your plan type.')} theme="error">
          <div>{__('Upgrade your plan to use this feature.')}</div>
        </Notice>
      </Page>
    );
  }

  return (
    <Page title={__('Notifications')}>
      <Tabs>
        <TabsHeader>
          <Tab>{__('Notification policies')}</Tab>
          <Tab>{__('Notification history')}</Tab>
          <Tab>{__('Emails')}</Tab>
        </TabsHeader>
        <TabContent><NotificationPolicies /></TabContent>
        <TabContent><NotificationHistory /></TabContent>
        <TabContent><Emails /></TabContent>
      </Tabs>
    </Page>
  );
};

export default NotificationsContainer;
