import { get } from 'lodash';
import update from 'immutability-helper';
import {
  getInitialUiOptions,
  parseObject,
  formatManagerPermissions,
} from 'shared/helpers';
import { initialPortalData } from 'shared/constants';
import * as actionTypes from './actionTypes';

const handleInitialUiOptions = (data) => {
  const initialOptions = getInitialUiOptions();
  const uiOptions = get(data, 'ui_options');
  if (!uiOptions) {
    return { ...data, ui_options: initialOptions };
  }
  return { ...data, ui_options: parseObject(uiOptions, initialOptions) };
};

const handleInitialPortalData = (data) => {
  const portalData = get(data, 'portal_data');
  const offlinePortalData = get(data, 'offline_portal_data');
  const airgapPortalData = get(data, 'airgap_portal_data');
  return (
    {
      portal_data: parseObject(portalData, initialPortalData),
      offline_portal_data: parseObject(offlinePortalData, ''),
      airgap_portal_data: parseObject(airgapPortalData, ''),
    }
  );
};

const updateLabel = (oldLabelsList = [], updatedLabel) => {
  const updatedLabelID = get(updatedLabel, 'id');
  const newList = oldLabelsList.map((l) => {
    if (l.id === updatedLabelID) {
      return updatedLabel;
    }
    return l;
  });
  return newList;
};

const initialState = {
  loading: false,
  userPreferencesLoading: true,
  userPreferences: {},
  loadingCustomerLabels: true,
  customerLabels: [],
  loadingPermissionsList: true,
  permissionsList: [],
  loadingUserPermissions: true,
  userPermissions: [],
  loadingUserPermissionsTemplates: true,
  userPermissionsTemplates: [],
  loadingAccountIntegrations: true,
  accountIntegrations: [],
  loadingUserLabels: true,
  userLabels: [],
  userPortalPreferences: {},
  managerPermissionsLoading: false,
  managerPermissions: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.getCompanyInit:
      return Object.assign({}, state, {
        loading: true,
      });
    case actionTypes.getCompanySuccess:
      return Object.assign({}, state, {
        loading: false,
        details: action.company,
        features: action.company.features,
        userPortalPreferences: handleInitialPortalData(action.company, 'portal_data'),
      });
    case actionTypes.getCompanyError:
      return Object.assign({}, state, {
        loading: false,
      });
    case actionTypes.getCompanyFeaturesSuccess:
      return Object.assign({}, state, {
        loading: false,
        features: action.features,
      });
    case actionTypes.getCompanyFeaturesError:
      return Object.assign({}, state, {
        loading: false,
      });
    case actionTypes.updateUserPreferences:
      return Object.assign({}, state, {
        userPreferences: handleInitialUiOptions(action.payload),
      });
    case actionTypes.updatePortalData:
      return Object.assign({}, state, {
        userPortalPreferences: action.payload,
      });
    case actionTypes.getUserPreferencesInit:
      return Object.assign({}, state, {
        userPreferencesLoading: true,
      });
    case actionTypes.getUserPreferencesSuccess:
      return Object.assign({}, state, {
        userPreferencesLoading: false,
        userPreferences: handleInitialUiOptions(action.payload),
      });
    case actionTypes.getUserPreferencesError:
      return Object.assign({}, state, {
        userPreferencesLoading: false,
      });
    case actionTypes.getManagerPermissionsInit:
      return Object.assign({}, state, {
        managerPermissionsLoading: true,
      });
    case actionTypes.getManagerPermissionsSuccess:
      return Object.assign({}, state, {
        managerPermissionsLoading: false,
        managerPermissions: formatManagerPermissions(action.payload),
      });
    case actionTypes.getManagerPermissionsError:
      return Object.assign({}, state, {
        managerPermissionsLoading: false,
      });
    case actionTypes.clearActiveCompany:
      return Object.assign({}, initialState);
    case actionTypes.refreshCardData:
      return update(state, {
        details: {
          payment_last4: { $set: action.payment_last4 },
          payment_expires: { $set: action.payment_expires },
          payment_card_type: { $set: action.payment_card_type },
          stripe_tax_id: { $set: action.stripe_tax_id },
          subscription_cycle: { $set: action.subscription_cycle },
        },
      });
    case actionTypes.refreshTrialDays:
      return update(state, {
        details: {
          trial_days: { $set: action.trial_days },
        },
      });
    case actionTypes.refreshCompanyData:
      return Object.assign({}, state, {
        loading: false,
        details: action.company,
        features: action.company.features,
        userPortalPreferences: handleInitialPortalData(action.company, 'portal_data'),
      });
    case actionTypes.getCustomerLabelsInit:
      return { ...state, loadingCustomerLabels: true };
    case actionTypes.getCustomerLabelsSuccess:
      return { ...state, loadingCustomerLabels: false, customerLabels: action.payload };
    case actionTypes.getCustomerLabelsError:
      return { ...state, loadingCustomerLabels: false };
    case actionTypes.addNewCustomerLabel:
      return { ...state, customerLabels: [...state.customerLabels, action.payload] };
    case actionTypes.removeLabel:
      return {
        ...state,
        customerLabels: state.customerLabels.filter(cl => cl.id !== action.payload.id),
      };
    case actionTypes.updateLabel:
      return {
        ...state,
        customerLabels: updateLabel(state.customerLabels, action.payload),
      };
    case actionTypes.getPermissionsInit:
      return { ...state, loadingPermissionsList: true };
    case actionTypes.getPermissionsSuccess:
      return { ...state, loadingPermissionsList: false, permissionsList: action.payload };
    case actionTypes.getPermissionsError:
      return { ...state, loadingPermissionsList: false };
    case actionTypes.getUserPermissionsInit:
      return { ...state, loadingUserPermissions: true };
    case actionTypes.getUserPermissionsSuccess:
      return { ...state, loadingUserPermissions: false, userPermissions: action.payload };
    case actionTypes.getUserPermissionsError:
      return { ...state, loadingUserPermissions: false };
    case actionTypes.getUserPermissionsTemplatesInit:
      return { ...state, loadingUserPermissionsTemplates: true };
    case actionTypes.getUserPermissionsTemplatesSuccess:
      return { ...state, loadingUserPermissionsTemplates: false, userPermissionsTemplates: action.payload };
    case actionTypes.getUserPermissionsTemplatesError:
      return { ...state, loadingUserPermissionsTemplates: false };
    case actionTypes.getAccountIntegrationsInit:
      return { ...state, loadingAccountIntegrations: true };
    case actionTypes.getAccountIntegrationsSuccess:
      return { ...state, loadingAccountIntegrations: false, accountIntegrations: action.payload };
    case actionTypes.getAccountIntegrationsError:
      return { ...state, loadingAccountIntegrations: false };
    case actionTypes.getUserLabelsInit:
      return { ...state, loadingUserLabels: true };
    case actionTypes.getUserLabelsSuccess:
      return { ...state, loadingUserLabels: false, userLabels: action.payload };
    case actionTypes.getUserLabelsError:
      return { ...state, loadingUserLabels: false };
    case actionTypes.addNewUserLabel:
      return { ...state, userLabels: [...state.userLabels, action.payload] };
    case actionTypes.removeUserLabel:
      return {
        ...state,
        userLabels: state.userLabels.filter(cl => cl.id !== action.payload.id),
      };
    case actionTypes.updateUserLabel:
      return {
        ...state,
        userLabels: updateLabel(state.userLabels, action.payload),
      };
    default:
      return state;
  }
}
