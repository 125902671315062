import React, { useState, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
// import ReactJson from 'react-json-view';
import {
  Button,
  ContentLoader,
  IconInfo,
  Subtitle,
} from 'shared/components';
import { sendAWSCognitoJwt, setCurrentUser } from 'src/account/actions';
import './styles.scss';

const parseHash = (hash) => {
  if (!hash) {
    return null;
  }
  const data = {};
  const paramPairs = hash.split('#')[1].split('&');
  paramPairs.forEach((p) => {
    const param = p.split('=');
    const [key, value] = param;
    data[key] = value;
  });
  return data;
};

const SSOContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const currentUser = useSelector(state => get(state, 'account.user'));
  const currentUserID = get(currentUser, 'id');

  const [isLoading, setLoading] = useState(true);
  // /api/v1/cognito/init/
  // - Company not found.
  // - User pool id not found.
  // - User pool client not found.
  // - SSO not allowed

  // /api/v1/cognito/jwt/
  // - Company not found.
  // - User pool id not found.
  // - Public key not found in `jwks.json`.
  // - Signature verification failed.
  // - Token is expired.
  // - Token was not issued for this audience.
  // - Missing user email.
  // - User inactive.
  // - User inactive on company.
  //   User not found.
  // User not found in company.
  // SSO not allowed
  const [error, setError] = useState(null);

  useEffect(() => {
    if (currentUserID) {
      history.push('/');
    }
  }, [currentUserID, history]);

  const getSSOUser = useCallback((data) => {
    const companyCode = window.localStorage.getItem('companyCode');
    sendAWSCognitoJwt(companyCode, data)
      .then((res) => {
        const user = get(res, 'data.user');
        dispatch(setCurrentUser(user));
        setLoading(false);
      })
      .catch((err) => {
        const errMsg = get(err, 'response.data.detail');
        setError(errMsg);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const locationHash = get(location, 'hash');
    const ssoData = parseHash(locationHash);

    if (!ssoData) {
      history.push('/login');
    } else {
      getSSOUser(ssoData);
    }
  }, []);

  return (
    <div className="SSOContainer">
      <div className="sso-container">
        {isLoading && <ContentLoader />}
        {!!error && (
          <div className="SSOContainer-error">
            <IconInfo height="40px" width="40px" color="#ee5253" />
            <Subtitle text={__('Single Sign On')} />
            <div>{error}</div>
            <Button
              onClick={() => history.push('/login')}
            >
              {__('Back to login')}
            </Button>
          </div>
        )}
        {/* {!!response && (
          <div style={{ wordBreak: 'break-all', maxWidth: '300px' }}>
            <ReactJson
              src={JSON.parse(JSON.stringify(response))}
              name="response"
              collapsed={1}
              displayDataTypes={false}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default SSOContainer;
