import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
} from 'shared/components';
import { sendErrorReport } from 'shared/helpers';
import {
  validateRequiredValue,
  debouncedValidateRequiredValue,
} from 'shared/validation';
import { initAWSCognito } from 'src/account/actions';

const SSOCompanyCodeForm = ({
  closeCb,
}) => {
  const savedCompanyCode = window.localStorage.getItem('companyCode');

  const [isLoading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormDisplay] = useState(false);
  const [companyCode, setCompanyCode] = useState(savedCompanyCode || '');
  const [companyCodeError, setCompanyCodeError] = useState('');

  const validateValue = async (val, cb) => {
    let errors;
    try {
      errors = await validateRequiredValue(val);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate company code', { value: val });
    }
    if (errors) {
      return false;
    }
    return true;
  };

  const isFormValid = async () => {
    const isCompanyCodeValid = await validateValue(companyCode, setCompanyCodeError);
    return isCompanyCodeValid;
  };

  const handleSubmit = async () => {
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    setLoading(true);
    window.localStorage.setItem('companyCode', companyCode);
    initAWSCognito(companyCode)
      .then((res) => {
        window.location.href = get(res, 'data.redirect_to');
      })
      .catch((err) => {
        const errMsg = get(err, 'response.data.detail');
        setCompanyCodeError(errMsg);
        setLoading(false);
        Notification('error', __('Error occured'), __('Make sure that company code is valid'));
      });
    return true;
  };

  const handleClose = () => {
    if (!dirty) { return closeCb(); }
    return setDirtyFormDisplay(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      title={__('Single Sign On')}
      disabled={isLoading}
      size="sm"
    >
      <div className="SSOCompanyCodeForm">
        <div className="row input-cont">
          <Label inputId="companyCode" text={__('Company code')} />
          <TextInput
            disabled={isLoading}
            id="companyCode"
            type="text"
            value={companyCode}
            error={companyCodeError}
            handleChange={(val) => {
              setDirty(true);
              setCompanyCode(val);
              debouncedValidateRequiredValue(val).then(err => setCompanyCodeError(err));
            }}
            autoFocus
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
          />
        </div>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty}
            closeAlert={() => setDirtyFormDisplay(false)}
            closeCb={closeCb}
          />
        )}
      </div>
    </Modal>
  );
};

SSOCompanyCodeForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
};

export default SSOCompanyCodeForm;
