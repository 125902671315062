// Fetching current user
export const getCurrentUser = 'GET_CURRENT_USER';
export const getCurrentUserSuccess = 'GET_CURRENT_USER_SUCCESS';
export const getCurrentUserError = 'GET_CURRENT_USER_ERROR';
export const refreshCurrentUser = 'REFRESH_CURRENT_USER';

// Login
export const loginInit = 'LOGIN_INIT';
export const loginSuccess = 'LOGIN_SUCCESS';
export const loginError = 'LOGIN_ERROR';

// Logout
export const logoutInit = 'LOGOUT_INIT';
export const logoutSuccess = 'LOGOUT_SUCCESS';
export const logoutError = 'LOGOUT_ERROR';

// Active company
export const changeActiveCompany = 'CHANGE_ACTIVE_COMPANY';

// User companies
export const getUserCompaniesInit = 'GET_USER_COMPANIES_INIT';
export const getUserCompaniesSuccess = 'GET_USER_COMPANIES_SUCCESS';
export const getUserCompaniesError = 'GET_USER_COMPANIES_ERROR';

// Fetching users
export const clearUsers = 'CLEAR_USERS';
export const getUsersInit = 'GET_USERS_INIT';
export const getUsersSuccess = 'GET_USERS_SUCCESS';
export const getUsersError = 'GET_USERS_ERROR';

// debug
export const disableStaff = 'DISABLE_STAFF';

// sidebar
export const changeSidebarSize = 'CHANGE_SIDEBAR_SIZE';

// notifications
// events
export const getEventsInit = 'GET_EVENTS_INIT';
export const getEventsSuccess = 'GET_EVENTS_SUCCESS';
export const getEventsError = 'GET_EVENTS_ERROR';

// invoices
export const getInvoicesInit = 'GET_INVOICES_INIT';
export const getInvoicesSuccess = 'GET_INVOICES_SUCCESS';
export const getInvoicesError = 'GET_INVOICES_ERROR';
