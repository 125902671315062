import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { NavLink } from 'react-router-dom';
import {
  Button,
  ConfirmationPopup,
  DescriptionTable,
  JsonView,
  StatusCard,
  TooltipHeader,
  Subtitle,
  Notification,
} from 'shared/components';
import {
  displayValue,
  formatDate,
  displayMaxOveragesValue,
  capitalizeFirstLetter,
  getLicensePolicyFile,
  sendErrorReport,
} from 'shared/helpers';
import { defaultDateFormat, licenseTypes } from 'shared/constants';
import { checkCompanyConfigField } from 'shared/companyConfig';
import PlatformAuditLog from 'src/account/AuditLog/PlatformAuditLog';
import { resetLicenseConsumptions } from 'src/license/actions';
import './styles.scss';

const LicenseDetailsTable = ({
  companyID,
  currentProduct,
  isAuthUserBased,
  license,
  licensePolicy,
  refetchLicense,
}) => {
  const licenseID = get(license, 'id');
  const productID = get(currentProduct, 'id');
  const productName = get(currentProduct, 'product_name');
  const productAuth = get(currentProduct, 'authorization_method');
  const licenseStatusText = get(license, 'status');
  const licenseStatus = licenseStatusText === 'Active' ? 'success' : 'error';
  const isTrial = get(license, 'is_trial');
  const isConsumption = get(license, 'license_type') === licenseTypes.consumption;
  const isSubscription = get(license, 'license_type') === licenseTypes.subscription;
  const allowGracePeriod = get(license, 'allow_grace_period');
  const gracePeriod = get(license, 'grace_period');
  const hasMaintenanceEnabled = get(license, 'enable_maintenance_period');
  const isFloatingLicense = get(license, 'is_floating') || get(license, 'is_floating_cloud');
  const floatingTimeout = get(license, 'floating_timeout') || get(currentProduct, 'floating_timeout');
  const canBorrow = get(license, 'can_borrow');
  const isEnabled = get(license, 'enabled');
  const licenseStartDate = get(license, 'start_date');

  const [resettingConsumptions, setResettingConsumptions] = useState(false);
  const [isResetConsumptionsConfirmationDisplayed, setResetConsumptionsConfirmationDisplayed] = useState(false);

  const licensePolicyContent = () => (
    <div className="license-policy-content">
      <div className="policy-name">{get(licensePolicy, 'name')}</div>
      <div className="button-container">
        <Button
          size="sm"
          // loading={licenseRefreshLoading === get(row, 'original.hardware_id')}
          onClick={() => getLicensePolicyFile(licensePolicy)}
        >
          {__('Download license policy file')}
        </Button>
      </div>
    </div>
  );

  const resetTotalConsumptions = useCallback(() => {
    setResettingConsumptions(true);

    resetLicenseConsumptions(licenseID, companyID)
      .then(() => {
        refetchLicense();
        Notification('success', __('License consumptions reset successfully'));
        setResettingConsumptions(false);
        setResetConsumptionsConfirmationDisplayed(false);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot reset license consumptions');
        setResettingConsumptions(false);
        Notification('error', __('Error occured'), __('Your changes were not saved'));
      });
  }, [licenseID]);

  useEffect(() => {
    refetchLicense();
  }, [refetchLicense]);

  return (
    <div className="LicenseDetailsTable license-details-tab">
      <DescriptionTable
        details={[
          {
            label: __('Product'),
            value: <NavLink to={`/${companyID}/products/${productID}`}>{displayValue(productName)}</NavLink>,
          },
          { label: __('License type'), value: displayValue(capitalizeFirstLetter(get(license, 'license_type'))) },
          {
            label: licensePolicy ? __('License policy') : null,
            value: licensePolicyContent(),
          },
          {
            label: licenseStartDate ? TooltipHeader(__('Start Date'), __('License cannot be activated before this date')) : null,
            value: formatDate(licenseStartDate, defaultDateFormat),
          },
          {
            label: isEnabled ? TooltipHeader(__('Expiration Date')) : null,
            value: formatDate(get(license, 'validity_period'), defaultDateFormat),
          },
          {
            label: !isTrial ? __('Valid duration') : null,
            value: displayValue(get(license, 'valid_duration')),
          },
          {
            label: (isSubscription && allowGracePeriod) ? __('Grace period') : null,
            value: `${gracePeriod} ${__('hours')}`,
          },
          { label: __('Status'), value: <StatusCard text={licenseStatusText} status={licenseStatus} /> },
          { label: __('Created on'), value: formatDate(get(license, 'created_at')) },
          { label: __('Activated on'), value: formatDate(get(license, 'time_activated')) },
          { label: __('Last checked'), value: formatDate(get(license, 'last_check')) },
          { label: __('Last updated'), value: formatDate(get(license, 'updated_at')) },
          {
            label: isEnabled ? null : __('Disabled on'),
            value: formatDate(get(license, 'time_disabled')),
          },
          {
            label: checkCompanyConfigField(companyID, 'maxActivations') ? __('Max activations') : null,
            value: get(license, 'allow_unlimited_activations') ? __('Unlimited') : displayValue(get(license, 'max_activations')),
          },
          { label: __('Times activated'), value: displayValue(get(license, 'times_activated'), '0') },
          { label: __('Transfer count'), value: displayValue(get(license, 'transfer_count'), '0') },
          {
            label: get(license, 'max_transfers') ? __('Device transfer limit') : null,
            value: get(license, 'max_transfers') === -1 ? __('Device transfer not allowed') : displayValue(get(license, 'max_transfers')),
          },
          {
            label: checkCompanyConfigField(companyID, 'isTrial') ? __('Is trial') : null,
            value: get(license, 'is_trial') ? __('Yes') : __('No'),
          },
          {
            label: (checkCompanyConfigField(companyID, 'isTrial') && isTrial && get(license, 'trial_days')) ? __('Trial days') : null,
            value: displayValue(get(license, 'trial_days')),
          },
          {
            label: __('Authorization method'),
            value: capitalizeFirstLetter(displayValue(productAuth)),
          },
          {
            label: isAuthUserBased && (get(license, 'max_license_users') || get(license, 'max_license_users') === 0) ? __('Max license users') : null,
            value: get(license, 'max_license_users') === 0 ? __('Unlimited') : displayValue(get(license, 'max_license_users')),
          },
          // consumption license type data
          {
            label: isConsumption ? __('Max consumptions') : null,
            value: get(license, 'allow_unlimited_consumptions') ? __('Unlimited') : displayValue(get(license, 'max_consumptions'), '0'),
          },
          {
            label: isConsumption ? __('Total consumptions') : null,
            value: () => {
              const value = displayValue(get(license, 'total_consumptions'), '0');
              return (
                <div className="total-consumptions-cell">
                  <div className="value">{value}</div>
                  <Button
                    size="sm"
                    disabled={resettingConsumptions}
                    onClick={() => setResetConsumptionsConfirmationDisplayed(true)}
                  >
                    {__('Reset')}
                  </Button>
                </div>
              );
            },
          },
          {
            label: isConsumption ? __('Allow overages') : null,
            value: get(license, 'allow_overages') ? __('Yes') : __('No'),
          },
          {
            label: isConsumption ? __('Max overages') : null,
            value: displayMaxOveragesValue(license),
          },
          {
            label: isConsumption ? __('Reset consumption') : null,
            value: get(license, 'reset_consumption') ? __('Yes') : __('No'),
          },
          {
            label: isConsumption ? __('Consumption period') : null,
            value: displayValue(capitalizeFirstLetter(get(license, 'consumption_period'))),
          },
          // floating licenses data
          {
            label: get(license, 'is_floating') ? __('Offline floating license') : null,
            value: get(license, 'is_floating') ? __('Yes') : __('No'),
          },
          {
            label: get(license, 'is_floating_cloud') ? __('Is floating cloud') : null,
            value: get(license, 'is_floating_cloud') ? __('Yes') : __('No'),
          },
          {
            label: isFloatingLicense ? __('Max simultaneous license users') : null,
            value: displayValue(get(license, 'floating_users')),
          },
          {
            label: isFloatingLicense ? __('Floating timeout') : null,
            value: `${displayValue(floatingTimeout)} min`,
          },
          {
            label: get(license, 'is_floating_cloud') ? __('Floating in use') : null,
            value: `${get(license, 'floating_in_use_devices') || '0'} / ${get(license, 'floating_users')}`,
          },
          {
            label: canBorrow ? __('Can borrow') : null,
            value: canBorrow ? __('Yes') : __('No'),
          },
          {
            label: canBorrow ? __('Max borrow time') : null,
            value: `${displayValue(get(license, 'max_borrow_time'))} ${__('hours')}`,
          },
          // rest of the license data
          {
            label: (checkCompanyConfigField(companyID, 'maintenancePeriod') && hasMaintenanceEnabled) ? __('Maintenance duration') : null,
            value: displayValue(get(license, 'maintenance_duration')),
          },
          {
            label: (checkCompanyConfigField(companyID, 'maintenancePeriod') && hasMaintenanceEnabled) ? __('Maintenance End Date') : null,
            value: formatDate(get(license, 'maintenance_period'), defaultDateFormat),
          },
          { label: __('Prevent virtual machine'), value: get(license, 'prevent_vm') ? __('Yes') : __('No') },
          { label: __('Note'), value: displayValue(get(license, 'note')) },
          {
            label: __('Metadata'),
            value: (
              <JsonView
                value={get(license, 'metadata')}
                name="metadata"
              />
            ),
          },
        ]}
      />
      <div className="Logs">
        <Subtitle text={__('Logs')} />
        <PlatformAuditLog license={license} />
      </div>
      {isResetConsumptionsConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setResettingConsumptions(false);
            setResetConsumptionsConfirmationDisplayed(false);
          }}
          confirmCb={resetTotalConsumptions}
          title={`${__('Are you sure you want to reset total consumptions of this license?')}`}
          confirmText={__('Reset')}
          theme="error"
          disabled={resettingConsumptions}
        />
      )}
    </div>
  );
};

LicenseDetailsTable.propTypes = {
  companyID: PropTypes.number.isRequired,
  currentProduct: PropTypes.object.isRequired,
  isAuthUserBased: PropTypes.bool.isRequired,
  license: PropTypes.object.isRequired,
  licensePolicy: PropTypes.object,
  refetchLicense: PropTypes.func.isRequired,
};

LicenseDetailsTable.defaultProps = {
  licensePolicy: null,
};

export default LicenseDetailsTable;
