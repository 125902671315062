import React, { useState, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  Button,
  ContentLoader,
  ConfirmationPopup,
  IconClipboard,
  NotFound,
  Notice,
  Notification,
  Page,
  PermissionMissingNotificationTitle,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
} from 'shared/components';
import {
  platformFeatures,
  userPermissions,
} from 'shared/constants';
import {
  getDisabledMessage,
  isFeatureEnabled,
  sendErrorReport,
  copyText,
  checkUserPermission,
} from 'shared/helpers';
import {
  checkCompanyConfigField,
} from 'shared/companyConfig';
import { getCompanySilent } from 'src/company/actions';
import {
  archiveProduct,
  changeProductStatus,
  updateVersion,
  fetchInstallationFiles,
  getProduct,
  getProducts,
} from 'src/product/actions';
import {
  EditProductForm,
  ProductVersionForm,
} from '../Forms';
import {
  LicensePolicies,
  ProductDetails,
  Features,
  CustomFields,
  Versions,
} from './components';
import BundleContainer from '../BundleContainer';
import './styles.scss';

const ProductContainer = () => {
  const canManageProducts = checkUserPermission(userPermissions.products_write);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const companyPlan = useSelector(state => get(state, 'company.details.plan_type'));
  const { productId } = useParams();

  const [isLoading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [product, setProduct] = useState(null);
  const [isEditFormDisplayed, setEditFormDisplay] = useState(false);
  const [versions, setVersions] = useState([]);
  const [isLoadingVersion, setLoadingVersions] = useState(true);
  const [versionToEdit, setVersionToEdit] = useState(null);
  const [isEditVersionFormDisplayed, setEditVersionFormDisplay] = useState(false);
  const [isStatusChanging, setStatusChanging] = useState(false);
  const [isEnableProductConfirmationDisplayed, setEnableProductConfirmationDisplay] = useState(false);
  const [isDeleteProductConfirmationDisplayed, setDeleteProductConfirmationDisplay] = useState(false);
  const [archivingProduct, setArchivingProduct] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const getVersions = useCallback(() => {
    fetchInstallationFiles(productId, companyID)
      .then((res) => {
        setVersions(get(res, 'data.results') || []);
        setLoadingVersions(false);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot fetch product versions');
        setLoadingVersions(false);
        Notification('error', __('Error occured'), __('We could not load the product versions.'));
      });
  }, [productId]);

  const fetchProductData = useCallback(() => {
    getProduct(productId, companyID)
      .then((res) => {
        setProduct(get(res, 'data'));
        setLoading(false);
        getVersions();
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot fetch product data');
        setLoading(false);
        setNotFound(true);
      });
  }, [companyID, productId]);

  useEffect(() => {
    const queryParams = get(location, 'search');
    if (!queryParams) {
      setSelectedTab(0);
    }
    if (queryParams.indexOf('st=0') >= 0) {
      setSelectedTab(0);
    }
    if (queryParams.indexOf('st=1') >= 0) {
      setSelectedTab(1);
    }
    if (queryParams.indexOf('st=2') >= 0) {
      setSelectedTab(2);
    }
    if (queryParams.indexOf('st=3') >= 0) {
      setSelectedTab(3);
    }
    if (queryParams.indexOf('st=4') >= 0) {
      setSelectedTab(4);
    }
  }, [location]);

  useEffect(() => {
    fetchProductData();
  }, [fetchProductData]);

  const handleManageProductClick = (cb) => {
    if (!canManageProducts) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.products_write} />,
        __('Contact you account admin for support.'),
      );
      return false;
    }
    cb(true);
    return true;
  };

  if (isLoading) {
    return (
      <Page><ContentLoader text={__('Getting product details')} /></Page>
    );
  }

  if (notFound) {
    return (
      <Page><NotFound /></Page>
    );
  }

  const isBundle = get(product, 'is_bundle');

  if (isBundle) {
    return (
      <BundleContainer
        companyID={companyID}
        bundle={product}
        refreshBundle={() => {
          setLoading(true);
          fetchProductData();
        }}
      />
    );
  }

  const isArchived = get(product, 'is_archived');
  const isActive = get(product, 'active');

  const handleProductStatus = (isEnabled) => {
    if (isEnabled && isActive) {
      return false; // Already enabled
    }
    if (!isEnabled && !isActive) {
      return false; // Already disabled
    }

    setStatusChanging(true);
    const productData = { active: isEnabled };

    changeProductStatus(productId, productData, companyID)
      .then((res) => {
        setProduct(get(res, 'data'));
        setStatusChanging(false);
        setEnableProductConfirmationDisplay(false);
        dispatch(getCompanySilent(companyID));
        Notification('success', isEnabled ? __('Product enabled') : __('Product disabled'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot edit product', productData);
        setStatusChanging(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
    return true;
  };

  const shouldEnableProduct = updatedVersions => updatedVersions.filter(v => v.enabled === true).length > 0;

  const handleVersion = (version, isEnabled) => {
    setStatusChanging(true);
    const versionData = { enabled: isEnabled };
    const versionID = get(version, 'id');

    updateVersion(versionID, versionData, companyID)
      .then((res) => {
        const updatedVersions = versions.map((v) => {
          if (v.id === versionID) return get(res, 'data');
          return v;
        });
        setVersions(updatedVersions);
        setStatusChanging(false);
        Notification('success', isEnabled ? __('Version enabled') : __('Version disabled'));
        const shouldEnable = shouldEnableProduct(updatedVersions);
        handleProductStatus(shouldEnable);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot edit product version', versionData);
        setStatusChanging(false);
        Notification('error', isEnabled ? __('Version enabling failed') : __('Version disabling failed'));
      });
  };

  const handleProductArchive = () => {
    setArchivingProduct(true);
    const productData = { is_archived: true };

    archiveProduct(productId, productData, companyID)
      .then(() => dispatch(getProducts(companyID)))
      .then(() => {
        history.push(`/${companyID}/products`);
        Notification('success', __('Product succesfully archived'));
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot archive product', productData);
        setArchivingProduct(false);
        Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
      });
  };

  const handleFieldCopy = (val, desc) => {
    copyText(val);
    Notification('success', `${desc} ${__('copied to clipboard')}`);
  };

  const createProductTitle = (productName) => {
    const label = __('Product name');
    return (
      <div className="product-title-container">
        {__('Product')}
        &nbsp;-&nbsp;
        <span>{productName}</span>
        <button
          type="button"
          className="clipboard-btn"
          onClick={() => handleFieldCopy(productName, label)}
        >
          <IconClipboard width="21.5" height="20" viewBox="0 0 51.5 50" />
        </button>
      </div>
    );
  };

  return (
    <Page title={createProductTitle(get(product, 'product_name'))} description={get(product, 'short_code')}>
      {isArchived && (
        <div className="ProductContainer-notice">
          <Notice
            theme="warning"
            size="sm"
            title={__('This product is archived')}
          />
        </div>
      )}
      <div className="list-header">
        <div>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_product)}
            notEnabledMessage={getDisabledMessage()}
            onClick={() => handleManageProductClick(setEditFormDisplay)}
            theme="info"
          >
            {__('Edit product')}
          </Button>
        </div>
        <div>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_product)}
            notEnabledMessage={getDisabledMessage()}
            onClick={() => handleManageProductClick(setDeleteProductConfirmationDisplay)}
          >
            {__('Archive product')}
          </Button>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_product)}
            notEnabledMessage={getDisabledMessage()}
            theme={isActive ? 'error' : 'success'}
            onClick={() => handleManageProductClick(setEnableProductConfirmationDisplay)}
          >
            {isActive ? __('Disable product') : __('Enable product')}
          </Button>
        </div>
      </div>
      <div className="ProductContainer-tabs">
        <Tabs
          selectedIndex={selectedTab}
          onSelect={(tabIndex) => {
            history.push({
              search: `?st=${tabIndex}`,
            });
            setSelectedTab(tabIndex);
          }}
        >
          <TabsHeader>
            <Tab>{__('Product details')}</Tab>
            {checkCompanyConfigField(companyID, 'productFeatures') && (
              <Tab>{__('Features')}</Tab>
            )}
            <Tab>{__('Custom fields')}</Tab>
            <Tab>{__('Versions')}</Tab>
            <Tab>{__('License policies')}</Tab>
          </TabsHeader>
          <TabContent>
            <ProductDetails
              companyID={companyID}
              companyPlan={companyPlan}
              product={product}
              versions={versions}
            />
          </TabContent>
          {checkCompanyConfigField(companyID, 'productFeatures') && (
            <TabContent>
              <Features
                companyID={companyID}
                product={product}
                refetchProduct={fetchProductData}
              />
            </TabContent>
          )}
          <TabContent>
            <CustomFields
              companyID={companyID}
              product={product}
            />
          </TabContent>
          <TabContent>
            <Versions
              isLoading={isLoadingVersion}
              handleVersionStatusChange={(version, status) => handleVersion(version, status)}
              showNewVersionForm={() => setEditVersionFormDisplay(true)}
              showEditVersionForm={(version) => {
                setVersionToEdit(version);
                setEditVersionFormDisplay(true);
              }}
              versions={versions}
              companyID={companyID}
              refetchProduct={fetchProductData}
            />
          </TabContent>
          <TabContent>
            <LicensePolicies product={product} companyID={companyID} refetchProduct={fetchProductData} />
          </TabContent>
        </Tabs>
      </div>
      {isEditFormDisplayed && (
        <EditProductForm
          closeForm={() => setEditFormDisplay(false)}
          refetchProduct={fetchProductData}
          product={product}
          companyPlan={companyPlan}
        />
      )}
      {isEditVersionFormDisplayed && (
        <ProductVersionForm
          closeModal={() => {
            setEditVersionFormDisplay(false);
            setVersionToEdit(null);
          }}
          fetchFiles={getVersions}
          productId={Number(productId)}
          productName={get(product, 'product_name')}
          version={versionToEdit}
          versions={versions}
          companyID={companyID}
        />
      )}
      {isEnableProductConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => setEnableProductConfirmationDisplay(false)}
          confirmCb={() => handleProductStatus(!isActive)}
          title={__(`Are you sure you want to ${isActive ? __('disable') : __('enable')} this product?`)}
          confirmText={isActive ? __('Disable') : __('Enable')}
          theme={isActive ? 'error' : 'success'}
          disabled={isStatusChanging}
        />
      )}
      {isDeleteProductConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => setDeleteProductConfirmationDisplay(false)}
          confirmCb={handleProductArchive}
          title={__('Are you sure you want to archive this product?')}
          confirmText={__('Archive')}
          theme="error"
          disabled={archivingProduct}
        />
      )}
    </Page>
  );
};

export default ProductContainer;
