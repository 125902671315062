import React from 'react';
import Base from './Base';

export default props => (
  <Base {...props}>
    <path
      d="M96.2322159,19.4196148l0.10112-15.6889267L81.1532745,3.7460341L41.1988449,43.7003822  c-3.0746155-1.2009811-6.4170036-1.8673744-9.9169979-1.8673744c-15.0645866,0-27.2767868,12.2122383-27.2767868,27.2767868  c0,15.0645828,12.2121992,27.276825,27.2767868,27.276825s27.2767887-12.2122421,27.2767887-27.276825  c0-3.5632477-0.5320549-6.556366-1.7746773-9.6766052l7.1106491-6.9181175h10.7869186L74.600647,41.2018661l4.9544754-4.9210892  l5.9951096-0.0641212V25.2731667l10.7831039-0.0012856L96.2322159,19.4196148z M28.4686127,79.7498398  c-4.5036316,0-8.1545181-3.6508789-8.1545181-8.1544724c0-4.5036316,3.6508865-8.1545181,8.1545181-8.1545181  s8.1545181,3.6508865,8.1545181,8.1545181C36.6231308,76.0989609,32.9722443,79.7498398,28.4686127,79.7498398z"
    />
  </Base>
);
