import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Raven from 'raven-js';
import { get } from 'lodash';
import {
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import {
  ContentLoader,
  GlobalError,
  NotFound,
  TopHeader,
  Forbidden,
} from 'shared/components';
import { removeForbiddenCompanies } from 'shared/helpers';
import { getCurrentUser, getUserCompanies } from 'src/account/actions';
import CompanyContainer from 'src/company/CompanyContainer';
import CompanyList from 'src/company/components/CompanyList';
import CompanySelector from 'src/company/components/CompanySelector';
import ProfileContainer from 'src/user/ProfileContainer';
import Login2FASetup from 'src/user/containers/Login2FASetup';
import ConsentForm from '../ConsentForm';

class AppContainer extends Component {
  constructor() {
    super();

    this.state = {
      hasError: false,
    };

    this.reRenderTheApp = this.reRenderTheApp.bind(this);
  }

  componentDidMount() {
    const { getUserCompaniesAction } = this.props;
    getUserCompaniesAction();
  }

  componentDidUpdate() {
    const {
      activeCompany,
      companies,
      companiesLoading,
      history,
      location,
      user,
    } = this.props;
    if (
      !companiesLoading &&
      location.pathname === '/' &&
      companies && companies.length
    ) {
      const companiesList = removeForbiddenCompanies(user, companies);
      const firstCompany = activeCompany || get(companiesList, '[0].id');
      const isStaff = get(user, 'is_staff');

      if (!companiesList.length) {
        return false;
      }

      if (firstCompany && !isStaff) {
        history.push(`/${firstCompany}`);
        return true;
      }
    }
    return true;
    // if (
    //   !companiesLoading &&
    //   activeCompany &&
    //   (location.pathname === '/' || location.pathname === '/profile')
    // ) {
    //   clearActiveCompanyAction();
    // }
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Raven.captureException(error, { extra: errorInfo });
  }

  reRenderTheApp() {
    this.forceUpdate();
  }

  render() {
    const { hasError } = this.state;
    const {
      companiesLoading,
      getCurrentUserAction,
      location,
      userConsent,
      user,
      companies,
    } = this.props;

    const is2FAUserEnabled = get(user, 'twofa_required');
    const is2FACompanyEnforced = get(user, 'twofa_company_required');
    const companiesList = removeForbiddenCompanies(user, companies);

    if (hasError) {
      return <GlobalError />;
    }

    if (userConsent === null) {
      return (
        <ConsentForm
          userId={user.id}
          refetchUser={getCurrentUserAction}
        />
      );
    }

    if (is2FACompanyEnforced && !is2FAUserEnabled) {
      return (
        <Login2FASetup />
      );
    }

    if (companiesLoading) {
      return <ContentLoader text="Getting your organizations" />;
    }

    if (!companiesList.length) {
      return (
        <div className="AppContainer">
          <TopHeader
            forceRerender={this.reRenderTheApp}
          >
            <CompanySelector />
          </TopHeader>
          <Forbidden text={__('Your account has been disabled')} />
        </div>
      );
    }

    return (
      <div className="AppContainer">
        <Route
          path="/"
          render={() => (
            <TopHeader
              forceRerender={this.reRenderTheApp}
            >
              <CompanySelector />
            </TopHeader>
          )}
        />
        <Switch>
          <Route path="/" exact component={CompanyList} />
          <Route path="/profile" component={ProfileContainer} />
          <Route path="/:companyId" render={() => <CompanyContainer location={location} />} />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

AppContainer.propTypes = {
  activeCompany: PropTypes.number,
  companies: PropTypes.array,
  companiesLoading: PropTypes.bool.isRequired,
  getCurrentUserAction: PropTypes.func.isRequired,
  getUserCompaniesAction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object,
  userConsent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AppContainer.defaultProps = {
  activeCompany: null,
  companies: [],
  user: null,
  userConsent: null,
};

const mapStateToProps = state => ({
  activeCompany: get(state, 'company.details.id'),
  companies: get(state, 'account.companies'),
  companiesLoading: get(state, 'account.companiesLoading'),
  user: get(state, 'account.user'),
  userConsent: get(state, 'account.user.acquired_consent'),
});

const mapDispatchToProps = {
  getCurrentUserAction: getCurrentUser,
  getUserCompaniesAction: getUserCompanies,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer));
