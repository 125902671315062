import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  ContentLoader,
  Label,
  Notification,
  TextInput,
  Subtitle,
} from 'shared/components';
import { sendErrorReport } from 'shared/helpers';
import { validateRequiredValue } from 'shared/validation';
import version from 'src/version';
import {
  verify2FA,
  login,
  loginError,
  loginSuccess,
  initAWSCognito,
} from 'src/account/actions';
import SSOCompanyCodeForm from './SSOCompanyCodeForm';
import { getInfoText } from './helpers';
import './styles.scss';

const LoginContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const searchQuery = get(location, 'search');
  const ssoQuery = searchQuery.split('?sso=')[1];

  const isLoading = useSelector(state => get(state, 'account.checkingUser'));
  const currentUser = useSelector(state => get(state, 'account.user'));
  const currentUserID = get(currentUser, 'id');

  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passcode, setPasscode] = useState('');
  const [passcodeError, setPasscodeError] = useState('');
  const [show2FA, set2FADisplay] = useState(false);
  const [twofaLoading, setTwofaLoading] = useState(false);
  const [displayCompanyCodeForm, setCompanyCodeFormDisplay] = useState(false);
  const [checkingSSO, setCheckingSSO] = useState(true);

  useEffect(() => {
    if (currentUserID) {
      history.push('/');
    }
    if (ssoQuery) {
      window.localStorage.setItem('companyCode', ssoQuery);
      initAWSCognito(ssoQuery)
        .then((res) => {
          window.location.href = get(res, 'data.redirect_to');
        })
        .catch(() => {
        });
    } else {
      setCheckingSSO(false);
    }
  }, [currentUserID, history, ssoQuery]);

  // validation methods
  const validateValue = async (val, cb) => {
    let errors;
    try {
      errors = await validateRequiredValue(val);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate 2FA passcode');
    }
    if (errors) { return false; }
    return true;
  };

  const isFormValid = async () => {
    const isPasscodeValid = await validateValue(passcode, setPasscodeError);
    return isPasscodeValid;
  };

  const handle2FACodeSubmit = async (e) => {
    e.preventDefault();

    const isValid = await isFormValid();
    if (!isValid || twofaLoading) {
      return false;
    }

    setTwofaLoading(true);
    const trimmedCode = passcode.replace(/\s/g, '');

    verify2FA(trimmedCode)
      .then((res) => {
        if (!res.data) {
          Notification('error', __('Authentification code is not valid'), __('Two-factor authentication failed, please try again.'));
          setTwofaLoading(false);
          return false;
        }
        dispatch(loginSuccess(user));
        history.push('/');
        return true;
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot verify 2FA code', { code: passcode });
        setTwofaLoading(false);
        Notification('error', __('Error occured'));
        return false;
      });
    return true;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isLoading) {
      return false;
    }

    const loginData = {
      email,
      password,
    };

    dispatch(login(loginData))
      .then((res) => {
        const userData = get(res, 'data.user');
        const twofaRequired = get(userData, 'twofa_required');

        if (twofaRequired) {
          setUser(userData);
          set2FADisplay(true);
        } else {
          dispatch(loginSuccess(userData));
          history.push('/');
        }
      })
      .catch((err) => {
        dispatch(loginError(err));
        Notification('error', __('Unable to login'), __('You provided wrong credentials'));
      });
    return true;
  };

  let infoMessage = '';

  if (searchQuery) {
    infoMessage = getInfoText(searchQuery);
  }

  if (checkingSSO) {
    return <ContentLoader text="" />;
  }

  if (show2FA) {
    return (
      <div className="LoginContainer">
        <div className="form-container">
          <form
            className="sso-form"
            id="sso-form"
            onSubmit={handle2FACodeSubmit}
          >
            <fieldset disabled={twofaLoading}>
              <div className="row logo-cont">
                <img src="img/ls-logo.svg" alt="logo" height="90px" />
              </div>
              <div className="version-cont">{version}</div>
              <div className="twofa-view">
                <Subtitle text={__('Two-factor authentication')} />
                <div className="row input-cont">
                  <Label text={__('Authentication code')} inputId="passcode-input" />
                  <TextInput
                    disabled={twofaLoading}
                    handleChange={(val) => {
                      setPasscode(val);
                      validateValue(val, setPasscodeError);
                    }}
                    id="passcode-input"
                    type="text"
                    value={passcode}
                    error={passcodeError}
                    autoComplete={false}
                    autoFocus
                  />
                </div>
                <div className="row btn-cont">
                  <Button
                    disabled={twofaLoading}
                    size="lg"
                    theme="success"
                    type="submit"
                    onClick={handle2FACodeSubmit}
                  >
                    {__('Submit code')}
                  </Button>
                </div>
              </div>
              <div className="row contact">
                <p className="contact-us">
                  {__('If you are having trouble logging in to your account, please')}
                  <a href="https://licensespring.zendesk.com/hc/en-us/requests/new">
                    {__('contact support')}
                  </a>
                </p>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="LoginContainer">
      <div className="form-container">
        <form
          className="login-form"
          id="login-form"
          onSubmit={handleFormSubmit}
        >
          <fieldset disabled={isLoading}>
            <div className="row logo-cont">
              <img src="img/ls-logo.svg" alt="logo" height="90px" />
            </div>
            <div className="version-cont">{version}</div>
            {searchQuery && (
              <div className="row">
                <p>{__(infoMessage)}</p>
              </div>
            )}
            <div className="login-view">
              <div className="row input-cont">
                <Label inputId="login-email" text={__('Email')} />
                <TextInput
                  disabled={isLoading}
                  handleChange={val => setEmail(val)}
                  id="login-email"
                  type="email"
                  value={email}
                  autoFocus
                />
              </div>
              <div className="row input-cont">
                <Label inputId="login-pass" text={__('Password')} />
                <TextInput
                  disabled={isLoading}
                  handleChange={val => setPassword(val)}
                  id="login-pass"
                  type="password"
                  value={password}
                />
              </div>
              <div className="row forgot-pass">
                <a className="login-forgot" href="/accounts/reset-password/">
                  {`${__('Forgot your password')}?`}
                </a>
              </div>
              <div className="row btn-cont">
                <Button
                  disabled={isLoading}
                  size="lg"
                  theme="success"
                  type="submit"
                  onClick={handleFormSubmit}
                >
                  {__('Login')}
                </Button>
              </div>
              <div className="row btn-cont">
                <Button
                  disabled={isLoading}
                  size="sm"
                  theme="default"
                  type="button"
                  onClick={() => setCompanyCodeFormDisplay(true)}
                >
                  {__('Single Sign On')}
                </Button>
              </div>
              <div className="row create-account">
                {__('Don\'t have an account?')}
                &nbsp;
                <a className="register-redirect" href="https://licensespring.com/get-started/">
                  {__('Get started')}
                </a>
              </div>
            </div>
            <div className="row contact">
              <p className="contact-us">
                {__('If you are having trouble logging in to your account, please')}
                <a href="https://licensespring.zendesk.com/hc/en-us/requests/new">
                  {__('contact support')}
                </a>
              </p>
            </div>
          </fieldset>
        </form>
      </div>
      {displayCompanyCodeForm && (
        <SSOCompanyCodeForm
          closeCb={() => setCompanyCodeFormDisplay(false)}
        />
      )}
    </div>
  );
};

export default LoginContainer;
