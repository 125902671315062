import * as account from 'src/account/actionTypes';
import * as actionTypes from './actionTypes';

const initialState = {
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.getUsersInit:
      return Object.assign({}, state, {
        loading: true,
      });
    case actionTypes.getUsersSuccess:
      return Object.assign({}, state, {
        loading: false,
        list: action.payload,
      });
    case actionTypes.getUsersError:
      return Object.assign({}, state, {
        loading: false,
      });
    case actionTypes.clearUsers:
      return Object.assign({}, state, {
        list: undefined,
      });
    case account.logoutSuccess:
      return initialState;
    default:
      return state;
  }
}
