import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { sendErrorReport } from 'shared/helpers';
import {
  ContentLoader,
  DirtyFormAlert,
  Label,
  TextInput,
  Modal,
  Notification,
} from 'shared/components';
import { getCurrentUserSilent } from 'src/account/actions';
import {
  initTwofaSetup,
  finishTwofaSetup,
} from 'src/user/actions';
import './styles.scss';

const TwofaSetupForm = ({
  closeCb,
}) => {
  const dispatch = useDispatch();

  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, showDirtyForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [qrCodeLoading, setQrCodeLoading] = useState(true);
  const [qrCode, setQrCode] = useState('');
  const [manualCode, setManualCode] = useState('');
  const [passcode, setPasscode] = useState('');
  const [passcodeError, setPasscodeError] = useState('');

  const getQrCode = () => {
    initTwofaSetup()
      .then((res) => {
        const qr = get(res, 'data.provisioning_uri');
        const secretCode = get(res, 'data.secret');
        setQrCode(qr);
        setManualCode(secretCode);
        setQrCodeLoading(false);
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot init 2FA');
        setQrCodeLoading(false);
      });
  };

  useEffect(() => {
    getQrCode();
  }, []);

  const validatePasscode = (val) => {
    if (!val) {
      setPasscodeError(__('This field is required'));
      return false;
    }
    setPasscodeError('');
    return true;
  };

  const isFormValid = () => {
    const isPasscodeValid = validatePasscode(passcode);
    return isPasscodeValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = isFormValid();
    if (!isValid) {
      return false;
    }

    setLoading(true);
    const trimmedCode = passcode.replace(/\s/g, '');

    finishTwofaSetup(trimmedCode)
      .then((res) => {
        if (!res.data) {
          Notification('error', __('Authentification code is not valid'), __('Two-factor authentication setup failed, please try again.'));
          return setLoading(false);
        }
        Notification('success', __('Two-factor authentication enabled succesfully'), __('Two factor authentication will be required on your next login.'));
        dispatch(getCurrentUserSilent());
        return closeCb();
      })
      .catch((err) => {
        sendErrorReport(err, 'Cannot finish 2FA setup', { code: trimmedCode });
        setLoading(false);
        Notification('error', __('Error occured'), __('There was an error while saving your changes'));
      });
    return true;
  };

  const handleClose = () => {
    if (!dirty) {
      return closeCb();
    }
    return showDirtyForm(true);
  };

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={loading}
      size="lg"
      title={__('Setup two-factor authentication')}
    >
      <form onSubmit={handleSubmit} className="TwofaSetupForm" autoComplete="off">
        <div className="action-pane">
          <div className="qr-code-container">
            {qrCodeLoading ? <ContentLoader text={__('Getting QR code')} /> : (
              <div>
                <QRCode value={qrCode} />
                <div className="manual-key-cont">
                  <Label text={__('Key:')} inputId="passcode-input" />
                  <div className="manual-key">
                    <span>{manualCode.slice(0, 4)}</span>
                    <span>{manualCode.slice(4, 8)}</span>
                    <span>{manualCode.slice(8, 12)}</span>
                    <span>{manualCode.slice(12)}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="passcode-container">
            <Label text={__('Authentication code')} inputId="passcode-input" />
            <TextInput
              id="passcode-input"
              autoComplete={false}
              autoFocus
              value={passcode}
              error={passcodeError}
              disabled={loading}
              handleChange={(val) => {
                setDirty(true);
                setPasscode(val);
                validatePasscode(val);
              }}
            />
          </div>
        </div>
        <div className="directions-pane">
          <ul>
            <li>{__('Install an authenticator app (Google Authenticator, Authy or similar) on your device.')}</li>
            <li>{__('Scan the QR code with the authenticator app or enter the key manually to the authenticator app.')}</li>
            <li>{__('Submit the authentication code provided by your app.')}</li>
          </ul>
        </div>
      </form>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => showDirtyForm(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

TwofaSetupForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
};

export default TwofaSetupForm;
